import { useTranslation } from 'react-i18next';
import {
  TdsButton,
  TdsModal
} from '@scania/tegel-react';
import { useCallback, useEffect, useRef } from 'react';
import styles from '../../styles/ViewModal.module.css'

type ConfirmationModalProps = {
  header?: string;
  message?: string;
  size: ModalSize;
  selector: string;
  danger?: boolean;
  onCancel: Function;
  onConfirm: Function;
  confirmButtonText: string;
  cancelButtonText?: string;
  children?: React.ReactNode;

};

const ConfirmationModal = ({
  header,
  message,
  size,
  selector,
  danger,
  onCancel,
  onConfirm,
  confirmButtonText,
  cancelButtonText
}: ConfirmationModalProps) => {
  const { t } = useTranslation();
  const modalRef = useRef<HTMLTdsModalElement>(null);

  const cancelConfirmation = useCallback(() => {
    modalRef?.current?.closeModal();
    onCancel('');
  }, [onCancel]);

  const confirmConfirmation = useCallback(() => {
    modalRef?.current?.closeModal();
    onConfirm();
  }, [onConfirm]);


  useEffect(() => {
    const modal = modalRef.current;

    modal?.addEventListener('tdsClose', cancelConfirmation);
    return () => modal?.removeEventListener('tdsClose', cancelConfirmation);
  }, [cancelConfirmation]);

  if (selector.length && modalRef?.current) {
    modalRef?.current?.showModal();
  }

  return (
    <TdsModal 
      id='confirmation-modal' 
      header={header}
      size={size}      
      selector={(selector?.length > 0) ? `#${selector}` : ''}
      actions-position='sticky'
      ref={modalRef}
    >
      <span slot='body'>
        <p>{message}</p>
      </span>
      <span slot='actions'>
        <div className={styles.actionButtons}>
          <TdsButton
            size='sm'
            text={confirmButtonText}
            type='button'
            variant={danger ? 'danger' : 'primary'}
            onClick={confirmConfirmation}
          />
          <TdsButton
            size='sm'
            text={cancelButtonText ?? t('Avbryt')}
            type='button'
            variant='secondary'
            data-dismiss-modal
            onClick={cancelConfirmation}
          />
        </div>
      </span>
    </TdsModal>
  );
};

export default ConfirmationModal;

import React from 'react';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { KeycloakInitOptions } from 'keycloak-js';

import keycloak from './keycloak';
import authentication from '../api/authentication';
import { TdsSpinner } from '@scania/tegel-react';

const kcInitOptions: KeycloakInitOptions = {
  onLoad: 'check-sso',
  silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
};

export const kcIsLoading = (keycloak: any) => !keycloak;

type Props = {
  children: React.ReactNode;
};

const KeycloakProvider = ({ children }: Props) => (
  <ReactKeycloakProvider
    authClient={keycloak}
    initOptions={kcInitOptions}
    LoadingComponent={<div className='initialPageLoader'><TdsSpinner size='md' /></div>}
    isLoadingCheck={kcIsLoading}
    onEvent={authentication.handleKeycloakEvent}>
    {children}
  </ReactKeycloakProvider>
);

export default KeycloakProvider;

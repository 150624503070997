export const groupParams = [
  {
    categoryName: 'Driving',
    groups: {
      idling_time: [
        {
          name: 'custom.conditional.vehicle.without_pto.idle.time',
          unitType: 'time',
        },
        {
          name: 'custom.calculated.vehicle.without_pto.idle.time.proportion',
          unitType: 'timePercentage',
        },
      ],
      average_speed: [
        {
          name: 'custom.calculated.speed.average',
          unitType: 'distancePerTime',
        },
      ],
      average_speed_drive: [
        {
          name: 'custom.calculated.driving.speed.average',
          unitType: 'distancePerTime',
        },
      ],
      average_speed_drive_idle: [
        {
          name: 'custom.calculated.driving_idling_without_pto.speed.average',
          unitType: 'distancePerTime',
        },
      ],
      brake_applications: [
        {
          name: 'custom.calculated.driving.brake_pedal.count.per_distance',
          unitType: 'countPerDistance',
        },
        {
          name: 'driving.brake_pedal.count',
          unitType: 'count',
        },
      ],
      harsh_accelerations: [
        {
          name: 'custom.calculated.driving.harsh_acceleration.count.per_distance',
          unitType: 'countPerDistance',
        },
        {
          name: 'driving.harsh_acceleration.count',
          unitType: 'count',
        },
      ],
      harsh_brake_applications: [
        {
          name: 'custom.calculated.driving.harsh_brake.count.per_distance',
          unitType: 'countPerDistance',
        },
        {
          name: 'driving.harsh_brake.count',
          unitType: 'count',
        },
      ],
      speeding: [
        {
          name: 'driving.overspeeding.time',
          unitType: 'time',
        },
        {
          name: 'custom.calculated.driving.overspeeding.time.proportion',
          unitType: 'timePercentage',
        },
      ],
      overrevving: [
        {
          name: 'engine.overrevving.time',
          unitType: 'time',
        },
        {
          name: 'custom.calculated.engine.overrevving.time.proportion',
          unitType: 'timePercentage',
        },
      ],
      cruise_control: [
        {
          name: 'driving.cruise_control.distance',
          unitType: 'distance',
        },
        {
          name: 'custom.calculated.driving.cruise_control.distance.proportion',
          unitType: 'distancePercentage',
        },
      ],
      freewheel_coasting: [
        {
          name: 'driving.coasting.out_of_gear.distance',
          unitType: 'distance'
        },
        {
          name: 'custom.calculated.driving.coasting.out_of_gear.distance.proportion',
          unitType: 'distancePercentage',
        },
      ],
      powertrain_coasting: [
        {
          name: 'custom.conditional.driving.coasting.distance',
          unitType: 'distance'
        },
        {
          name: 'custom.calculated.driving.coasting.distance.proportion',
          unitType: 'distancePercentage'
        },
      ],
      powertrain_coasting_manual: [
        {
          name: 'custom.conditional.driving.without_cruise_control.coasting.distance',
          unitType: 'distance',
        },
        {
          name: 'custom.calculated.driving.without_cruise_control.coasting.distance.proportion',
          unitType: 'distancePercentage'
        },
      ],
    },
  },
  {
    categoryName: 'Consumption',
    groups: {
      total_reductant_used: [
        {
          name: 'engine.urea.used',
          unitType: 'volumeForDiesel',
        },
        {
          name: 'custom.conditional.my_scania_setting.engine.urea.consumption_rate',
          unitType: 'reductantForDistance'
        },
      ],
      fuel_consumption: [
        {
          name: 'vehicle.fuel_liquid2',
          unitType: 'volumeForDiesel',
        },
        {
          name: 'vehicle.fuel_gaseous2',
          unitType: 'volumeForGas',
        },
        {
          name: 'vehicle.energy',
          unitType: 'volumeForElectric',
        },
        {
          name: 'custom.calculated.consumption.total.fuel_liquid.per_time',
          unitType: 'volumeForDieselPerTime',
        },
        {
          name: 'custom.calculated.consumption.total.fuel_gaseous.per_time',
          unitType: 'volumeForGasPerTime',
        },
        {
          name: 'custom.calculated.consumption.total.energy.per_time',
          unitType: 'volumeForElectricPerTime',
        },
        {
          name: 'custom.conditional.my_scania_setting.total.fuel_liquid.consumption_rate',
          unitType: 'volumeForDieselVsDistance',
        },
        {
          name: 'custom.conditional.my_scania_setting.total.fuel_gaseous.consumption_rate',
          unitType: 'volumeForGasVsDistance',
        },
        {
          name: 'custom.conditional.my_scania_setting.total.energy.consumption_rate',
          unitType: 'volumeForElectricVsDistance',
        },
      ],
      fuel_consumption_idling: [
        {
          name: 'vehicle.without_pto.idle.fuel_liquid2',
          unitType: 'volumeForDiesel',
        },
        {
          name: 'vehicle.without_pto.idle.fuel_gaseous2',
          unitType: 'volumeForGas',
        },
        {
          name: 'vehicle.without_pto.idle.energy',
          unitType: 'volumeForElectric',
        },
        {
          name: 'custom.calculated.consumption.idling.fuel_liquid.per_time',
          unitType: 'volumeForDieselPerTime',
        },
        {
          name: 'custom.calculated.consumption.idling.fuel_gaseous.per_time',
          unitType: 'volumeForGasPerTime',
        },
        {
          name: 'custom.calculated.consumption.idling.energy.per_time',
          unitType: 'volumeForElectricPerTime',
        },
        {
          name: 'custom.conditional.my_scania_setting.idling.fuel_liquid.consumption_rate',
          unitType: 'volumeForDieselVsDistance',
        },
        {
          name: 'custom.conditional.my_scania_setting.idling.fuel_gaseous.consumption_rate',
          unitType: 'volumeForGasVsDistance',
        },
        {
          name: 'custom.conditional.my_scania_setting.idling.energy.consumption_rate',
          unitType: 'volumeForElectricVsDistance',
        },
      ],
      fuel_consumption_driving: [
        {
          name: 'custom.calculated.consumption.driving.fuel_liquid',
          unitType: 'volumeForDiesel',
        },
        {
          name: 'custom.calculated.consumption.driving.fuel_gaseous',
          unitType: 'volumeForGas',
        },
        {
          name: 'custom.calculated.consumption.driving.energy',
          unitType: 'volumeForElectric',
        },
        {
          name: 'custom.calculated.consumption.driving.fuel_liquid.per_time',
          unitType: 'volumeForDieselPerTime',
        },
        {
          name: 'custom.calculated.consumption.driving.fuel_gaseous.per_time',
          unitType: 'volumeForGasPerTime',
        },
        {
          name: 'custom.calculated.consumption.driving.energy.per_time',
          unitType: 'volumeForElectricPerTime',
        },
        {
          name: 'custom.conditional.my_scania_setting.driving.fuel_liquid.consumption_rate',
          unitType: 'volumeForDieselVsDistance',
        },
        {
          name: 'custom.conditional.my_scania_setting.driving.fuel_gaseous.consumption_rate',
          unitType: 'volumeForGasVsDistance',
        },
        {
          name: 'custom.conditional.my_scania_setting.driving.energy.consumption_rate',
          unitType: 'volumeForElectricVsDistance',
        },
      ],
      fuel_consumption_driving_and_idling: [
        {
          name: 'custom.calculated.consumption.driving_idling.fuel_liquid',
          unitType: 'volumeForDiesel',
        },
        {
          name: 'custom.calculated.consumption.driving_idling.fuel_gaseous',
          unitType: 'volumeForGas',
        },
        {
          name: 'custom.calculated.consumption.driving_idling.energy',
          unitType: 'volumeForElectric',
        },
        {
          name: 'custom.calculated.consumption.driving_idling.fuel_liquid.per_time',
          unitType: 'volumeForDieselPerTime',
        },
        {
          name: 'custom.calculated.consumption.driving_idling.fuel_gaseous.per_time',
          unitType: 'volumeForGasPerTime',
        },
        {
          name: 'custom.calculated.consumption.driving_idling.energy.per_time',
          unitType: 'volumeForElectricPerTime',
        },
        {
          name: 'custom.conditional.my_scania_setting.driving_idling.fuel_liquid.consumption_rate',
          unitType: 'volumeForDieselVsDistance',
        },
        {
          name: 'custom.conditional.my_scania_setting.driving_idling.fuel_gaseous.consumption_rate',
          unitType: 'volumeForGasVsDistance',
        },
        {
          name: 'custom.conditional.my_scania_setting.driving_idling.energy.consumption_rate',
          unitType: 'volumeForElectricVsDistance',
        },
      ],
      fuel_consumption_pto: [
        {
          name: 'custom.calculated.consumption.pto.fuel_liquid',
          unitType: 'volumeForDiesel',
        },
        {
          name: 'custom.calculated.consumption.pto.fuel_gaseous',
          unitType: 'volumeForGas',
        },
        {
          name: 'custom.calculated.consumption.pto.energy',
          unitType: 'volumeForElectric',
        },
        {
          name: 'custom.calculated.consumption.pto.fuel_liquid.per_time',
          unitType: 'volumeForDieselPerTime',
        },
        {
          name: 'custom.calculated.consumption.pto.fuel_gaseous.per_time',
          unitType: 'volumeForGasPerTime',
        },
        {
          name: 'custom.calculated.consumption.pto.energy.per_time',
          unitType: 'volumeForElectricPerTime',
        },
        {
          name: 'custom.conditional.my_scania_setting.pto.fuel_liquid.consumption_rate',
          unitType: 'volumeForDieselVsDistance',
        },
        {
          name: 'custom.conditional.my_scania_setting.pto.fuel_gaseous.consumption_rate',
          unitType: 'volumeForGasVsDistance',
        },
        {
          name: 'custom.conditional.my_scania_setting.pto.energy.consumption_rate',
          unitType: 'volumeForElectricVsDistance',
        },
      ],
    },
  },
  {
    categoryName: 'hybrid_vehicles',
    groups: {
      pause_mode: [
        {
          name: 'vehicle.pause.time',
          unitType: 'time',
        },
        {
          name: 'custom.calculated.vehicle.pause.time.proportion',
          unitType: 'timePercentage',
        },
        {
          name: 'custom.calculated.vehicle.driving.pause.distance',
          unitType: 'distance',
        },
        {
          name: 'custom.calculated.vehicle.driving.pause.distance.proportion',
          unitType: 'distancePercentage',
        },
      ],
    },
  },
  {
    categoryName: 'Load',
    groups: {
      average_weight: [
        {
          name: 'custom.calculated.vehicle.weight.average',
          unitType: 'weight',
        },
      ],
      transport_work: [
        {
          name: 'vehicle.transport_work',
          unitType: 'transportWork',
        },
        {
          name: 'custom.calculated.vehicle.transport_work.per_liquid',
          unitType: 'transportWorkForDiesel',
        },
        {
          name: 'custom.calculated.vehicle.transport_work.per_gaseous',
          unitType: 'transportWorkForGas',
        },
        {
          name: 'custom.calculated.vehicle.transport_work.per_energy',
          unitType: 'transportWorkForElectric',
        },
      ],/* commenting until BE supports it
      trailer_weight: [
        {
          name: 'trailer.weight',
          unitType: 'weight',
        },
      ],*/
    },
  },
  {
    categoryName: 'Performance mode',
    groups: {
      performance_mode_economy: [
        {
          name: 'vehicle.performance_mode_economy.distance',
          unitType: 'distance',
        },
        {
          name: 'custom.calculated.vehicle.performance_mode_economy.distance.proportion',
          unitType: 'distancePercentage',
        },
      ],
      performance_mode_offroad: [
        {
          name: 'vehicle.performance_mode_offroad.distance',
          unitType: 'distance',
        },
        {
          name: 'custom.calculated.vehicle.performance_mode_offroad.distance.proportion',
          unitType: 'distancePercentage',
        },
      ],
      performance_mode_power: [
        {
          name: 'vehicle.performance_mode_power.distance',
          unitType: 'distance',
        },
        {
          name: 'custom.calculated.vehicle.performance_mode_power.distance.proportion',
          unitType: 'distancePercentage',
        },
      ],
      performance_mode_standard: [
        {
          name: 'vehicle.performance_mode_standard.distance',
          unitType: 'distance',
        },
        {
          name: 'custom.calculated.vehicle.performance_mode_standard.distance.proportion',
          unitType: 'distancePercentage',
        },
      ],
    },
  },
  {
    categoryName: 'pto',
    groups: {
      pto_time: [
        {
          name: 'custom.calculated.pto.time',
          unitType: 'time',
        },
        {
          name: 'custom.calculated.pto.time.proportion',
          unitType: 'timePercentage'
        },
      ],
    },
  },
  {
    categoryName: 'support_score',
    groups: {
      support_score: [
        {
          name: 'custom.calculated.driver_evaluation.score.average',
          unitType: 'supportScoreTotal',
        },
        {
          name: 'custom.calculated.driver_evaluation.score.anticipation',
          unitType: 'supportScoreAnticipation',
        },
        {
          name: 'custom.calculated.driver_evaluation.score.hill',
          unitType: 'supportScoreHill',
        },
        {
          name: 'custom.calculated.driver_evaluation.score.gear_selection',
          unitType: 'supportScoreGear',
        },
        {
          name: 'custom.calculated.driver_evaluation.score.hybrid_brake',
          unitType: 'supportScoreBrake',
        },
        {
          name: 'custom.calculated.driver_evaluation.score.wear',
          unitType: 'supportScoreWear',
        },
      ],
    },
  },
  {
    categoryName: 'Utilization',
    groups: {
      driven_distance: [
        {
          name: 'vehicle.odometer',
          unitType: 'distance',
        },
      ],
      driving_time: [
        {
          name: 'custom.calculated.driving.time',
          unitType: 'time',
        },
        {
          name: 'custom.calculated.driving.time.proportion',
          unitType: 'timePercentage',
        },
      ],
      engine_running_time: [
        {
          name: 'custom.conditional.engine.time.delta',
          unitType: 'time',
        },
      ],
      trailer_distance: [
        {
          name: 'vehicle.trailer.distance',
          unitType: 'distance',
        },
        {
          name: 'custom.calculated.vehicle.trailer.distance.proportion',
          unitType: 'distancePercentage'
        },
      ],
    },
  },
  {
    categoryName: 'equipment_information',
    groups: {
      equipment_information: [
        {
          name: 'info.equipment.brand',
          unitType: 'infoBrand',
        },
        {
          name: 'info.equipment.equipment_type',
          unitType: 'infoEquipmentType',
        },
        {
          name: 'info.equipment.model',
          unitType: 'infoModel',
        },
        {
          name: 'info.equipment.engine_designation',
          unitType: 'infoEngineDesignation',
        },
        {
          name: 'info.equipment.fuel_type',
          unitType: 'infoFuelType',
        },
        {
          name: 'info.equipment.propulsion_system',
          unitType: 'infoPropulsion',
        }
      ],
      odometer: [
        {
          name: 'vehicle.odometer.icl_view',
          unitType: 'distance',
        },
      ],
      total_engine_time: [
        {
          name: 'custom.conditional.engine.time',
          unitType: 'time',
        },
      ],
    },
  }
];

export const groupsExplanationsKeys: { [key: string]: { [key: string]: string } } = {
  average_weight: {
    metric: 'MouseOverAverageWeight',
    imperial: 'MouseOverAverageWeight'
  },
  total_reductant_used: {
    metric: '',
    imperial: ''
  },
  odometer: {
    metric: 'MoueOverOdometer',
    imperial: 'MoueOverOdometer'
  },
  driven_distance: {
    metric: 'MouseOverDistanceDriven',
    imperial: 'MouseOverDistanceDriven'
  },
  fuel_consumption: {
    metric: 'MouseOverFuelConsumption',
    imperial: 'MouseOverFuelConsumption'
  },
  fuel_consumption_idling: {
    metric: 'MouseOverFuelConsumptionIdling',
    imperial: 'MouseOverFuelConsumptionIdling'
  },
  fuel_consumption_driving: {
    metric: 'MouseOverFuelConsumptionDriving',
    imperial: 'MouseOverFuelConsumptionDriving'
  },
  fuel_consumption_driving_and_idling: {
    metric: 'MouseOverFuelConsumptionDrivingAndIdling',
    imperial: 'MouseOverFuelConsumptionDrivingAndIdling'
  },
  fuel_consumption_pto: {
    metric: 'MouseOverFuelConsumptionPTO',
    imperial: 'MouseOverFuelConsumptionPTO'
  },
  average_speed: {
    metric: 'MouseOverAverageDrivingSpeed',
    imperial: 'MouseOverAverageDrivingSpeed'
  },
  average_speed_drive: {
    metric: 'MouseOverAverageDrivingSpeed',
    imperial: 'MouseOverAverageDrivingSpeed'
  },
  average_speed_drive_idle: {
    metric: 'MouseOverAverageDrivingSpeed',
    imperial: 'MouseOverAverageDrivingSpeed'
  },
  brake_applications: {
    metric: 'MouseOverBrakeApplications',
    imperial: 'MouseOverBrakeApplications'
  },
  idling_time: {
    metric: 'MouseOverIdle',
    imperial: 'MouseOverIdleMPH'
  },
  cruise_control: {
    metric: 'MouseOverCruiseControl',
    imperial: 'MouseOverCruiseControl'
  },
  harsh_accelerations: {
    metric: 'MouseOverHarshAccelerations',
    imperial: 'MouseOverHarshAccelerations'
  },
  harsh_brake_applications: {
    metric: 'MouseOverHarshBrakeApplications',
    imperial: 'MouseOverHarshBrakeApplicationsMPH'
  },
  speeding: {
    metric: 'MouseOverSpeeding',
    imperial: 'MouseOverSpeeding'
  },
  overrevving: {
    metric: 'MouseOverEngineOverspeed',
    imperial: 'MouseOverEngineOverspeed'
  },
  powertrain_coasting: {
    metric: 'MouseOverCoasting',
    imperial: 'MouseOverCoasting'
  },
  powertrain_coasting_manual: {
    metric: 'MouseOverCoasting',
    imperial: 'MouseOverCoasting'
  },
  freewheel_coasting: {
    metric: 'MouseOverCoastingInNeutral',
    imperial: 'MouseOverCoastingInNeutral'
  },
  transport_work: {
    metric: 'MouseOverTransportWork',
    imperial: 'MouseOverTransportWork'
  },
  trailer_weight: {
    metric: '',
    imperial: ''
  },
  performance_mode_economy: {
    metric: 'MouseOverEconomyMode',
    imperial: 'MouseOverEconomyMode'
  },
  performance_mode_offroad: {
    metric: 'MouseOverOffRoadMode',
    imperial: 'MouseOverOffRoadMode'
  },
  performance_mode_power: {
    metric: 'MouseOverPowerMode',
    imperial: 'MouseOverPowerMode'
  },
  performance_mode_standard: {
    metric: 'MouseOverStandardMode',
    imperial: 'MouseOverStandardMode'
  },
  pause_mode: {
    metric: 'MouseOverPauseMode',
    imperial: 'MouseOverPauseMode'
  },
  pto_time: {
    metric: 'MouseOverPowerTakeOff',
    imperial: 'MouseOverPowerTakeOffMPH'
  },
  support_score: {
    metric: 'MouseOverDriverSupport',
    imperial: 'MouseOverDriverSupport'
  },
  driving_time: {
    metric: 'MouseOverDriving',
    imperial: 'MouseOverDrivingMPH'
  },
  engine_running_time: {
    metric: 'MouseOverEngineRunningTime',
    imperial: 'MouseOverEngineRunningTime'
  },
  total_engine_time: {
    metric: '',
    imperial: ''
  },
  trailer_distance: {
    metric: 'MouseOverDistanceWithTrailer',
    imperial: 'MouseOverDistanceWithTrailer'
  },
  equipment_information:  {
    metric: '',
    imperial: ''
  },
}

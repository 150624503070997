import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TdsBodyCell,
  TdsHeaderCell,
  TdsSpinner,
  TdsTable,
  TdsTableBody,
  TdsTableBodyRow,
  TdsTableBodyRowExpandable,
  TdsTableHeader,
  TdsTableToolbar,
  TdsButton,
  TdsIcon,
  TdsMessage
} from '@scania/tegel-react';

import {
  useGetEmissionSpecsQuery,
  useGetActiveCustomerVehiclesQuery,
  useCreateEmissionSpecMutation,
  useUpdateEmissionSpecMutation,
  useDeleteEmissionSpecMutation
} from '../../state/emissionSpecifications/query';

import EmissionSpecsModal from './EmissionSpecsModal';
import ConfirmationModal from '../common/ConfirmationModal';
import '../../styles/GeneralStyles.css';
import style from '../../styles/EmissionSpecsTable.module.css';

import SpecDetailsTable from './SpecDetailsTable';
import ToastContainer from '../ToastContainer';
import { generateSecureNumber } from '../../utils/general';

interface onSpecificationUpdatedParams {
  oldSpec: EmissionSpecItem,
  newSpec: UpdateArgs_EmissionSpecItem
}

type EmissionSpecsTableProps = {
  vehicleIdentifier: string;
}

const EmissionSpecsTable = ({ vehicleIdentifier } : EmissionSpecsTableProps) => {
  const { t } = useTranslation();

  const filterTableRef = useRef<HTMLTdsTableElement>(null);

  const {
    data: serverData,
    isLoading: isEmissionSpecsLoading,
    isSuccess: emissionSpecsLoadedSuccessfully,
    isError: isErrorLoadingEmissionSpecs,
    isFetching: isEmissionsSpecsFetching,
  } = useGetEmissionSpecsQuery();

  const { data: activeVehicles } = useGetActiveCustomerVehiclesQuery();

  const [createEmissionSpec] = useCreateEmissionSpecMutation();
  const [updateEmissionSpec] = useUpdateEmissionSpecMutation();
  const [deleteEmissionSpec] = useDeleteEmissionSpecMutation();
  const [tableFilter, setTableFilter] = useState<string>('');
  const [filteredData, setFilteredData] = useState<EmissionSpecItem[]>([]);
  const [selectedSpec, setSelectedSpec] = useState<EmissionSpecItem | null>(null);
  const [toastsToDisplay, setToastsToDisplay] = useState<Array<Toast>>([]);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [emissionSpecSelector, setEmissionSpecSelector] = useState('');
  const [confirmationSelector, setConfirmationSelector] = useState('');


  useEffect(() => {
    const freeTextFilter = (item: EmissionSpecItem) => {
      return [item.displayName, item.description].some(field => field.toString().toLocaleLowerCase().includes(tableFilter.toLocaleLowerCase()));
    }
    if (serverData) {
      const newFilteredData = serverData.filter(freeTextFilter);
      setFilteredData(newFilteredData);
    }
  }, [serverData, tableFilter]);

  const pushNotificationToToast = (newNotification: Toast) => {
    setToastsToDisplay((toasts) => [...toasts, newNotification]);
  
    setTimeout(() => {
      removeToast(newNotification.id);
    }, 5000);
  }
  
  const removeToast = (toastId: number | string) => {
    setToastsToDisplay((toasts) =>
      toasts.filter((toast) => toast.id !== toastId)
    );
  }

  const onSpecificationUpdated = ({oldSpec, newSpec}: onSpecificationUpdatedParams) => {
    setFilteredData(data => data.map(item => item.id === oldSpec.id ? newSpec as EmissionSpecItem : item))
  }

  const saveSpecification = async (formValues: CreateArgs_EmissionSpecItem) => {
    const isEditing = emissionSpecSelector.includes('editSpec');

    let updatePayload: UpdateArgs_EmissionSpecItem | undefined;
    if (isEditing) {
      const { id, externalCustomerReference, isScaniaSpecification } = selectedSpec as UpdateArgs_EmissionSpecItem
      // @TODO set some error state if id or externalCustomerReference is missing
      updatePayload = {
        id,
        externalCustomerReference,
        isScaniaSpecification,
        ...formValues
      }
    }

    try {
      (isEditing && updatePayload) ? await updateEmissionSpec(updatePayload).unwrap() : await createEmissionSpec(formValues).unwrap();
      if (isEditing) onSpecificationUpdated({oldSpec: selectedSpec as EmissionSpecItem, newSpec: updatePayload as UpdateArgs_EmissionSpecItem});

      pushNotificationToToast({
        id: generateSecureNumber(),
        header: t('Lyckad'),
        subheader: t('UtsläppsspecifikationenHarSparats_'),
        variant: 'success',
      });
      
    } catch (err) {
      pushNotificationToToast({
        id: generateSecureNumber(),
        header: t('Failed'),
        subheader: t('EttFelHarUppstått'),
        variant: 'error',
      });
    }
  }

  const deleteSpecification = async () => {
    setConfirmationSelector('');

    try {
      await deleteEmissionSpec(selectedSpec?.id as number).unwrap();

      pushNotificationToToast({
        id: generateSecureNumber(),
        header: t('Lyckad'),
        subheader: t('UtsläppsspecifikationenHarTagitsBort_'),
        variant: 'success',
      });

    } catch (err) {
      pushNotificationToToast({
        id: generateSecureNumber(),
        header: t('Failed'),
        subheader: t('EttFelHarUppstått'),
        variant: 'error',
      });
    }
  }

  return (
    <>
      { (isEmissionSpecsLoading || isEmissionsSpecsFetching) && <div className='spinnerContainer'><TdsSpinner size='md' /></div>}
      { isErrorLoadingEmissionSpecs && (
        <TdsMessage
          variant='error'
          header={t('EttFelHarUppstått_FörsökIgenSenare_')}
        />
      )}
      {isModalVisible &&
        <EmissionSpecsModal
          header={emissionSpecSelector === 'createSpecButton' ? t('SkapaUtsläppsspecifikation'): t('VP_RedigeraUtsläpp') }
          selector={emissionSpecSelector}
          size='md'
          show={isModalVisible}
          onCancel={() => { setIsModalVisible(false); setEmissionSpecSelector(''); }}
          onSubmit={(formValues: CreateArgs_EmissionSpecItem) => { setIsModalVisible(false); setEmissionSpecSelector(''); saveSpecification(formValues) }}
          emissionSpec={selectedSpec}
          activeVehicles={activeVehicles}
          vehicleIdentifier={vehicleIdentifier}
        />
      }
      <ConfirmationModal
        header={`${t('TaBort')} ${selectedSpec?.displayName}`}
        message={`${t('VillDuTaBortUtsläppsspecifikationen_')} ${t('KopplingarTillUtsläppsspecifikationenTasOcksåBort_KopplingarnaGårInteAttÅterskapa_')}`}
        size='sm'
        selector={confirmationSelector}
        danger
        onCancel={() => setConfirmationSelector('')}
        onConfirm={deleteSpecification}
        confirmButtonText={t('TaBort')}
      />

      { !isEmissionSpecsLoading && !isEmissionsSpecsFetching && emissionSpecsLoadedSuccessfully &&
      <>
        <div className={style.buttonContainer}>
          <TdsButton
            variant='primary'
            size='sm'
            id='createSpecButton'
            onClick={() => { setSelectedSpec(null); setEmissionSpecSelector('createSpecButton');  setIsModalVisible(true); }}
          >
            <div slot='label'>{t('SkapaUtsläppsspecifikation')}</div> <TdsIcon slot='icon' size='16px' name='plus' />
          </TdsButton>
        </div>
        <div className='onlyTableContainer'>
          <TdsTable tableId='filter-table' noMinWidth responsive expandableRows ref={filterTableRef}>
            <TdsTableToolbar onTdsFilter={(event: any) => setTableFilter(event.detail.query)} filter />
            <TdsTableHeader>
              <TdsHeaderCell cellKey='displayName' cellValue={t('Namn')} />
              <TdsHeaderCell cellKey='description' cellValue={t('Beskrivning')} />
            </TdsTableHeader>
            <TdsTableBody>
              {!filteredData.length &&
              <TdsTableBodyRow>
                <td key='no-data-row-cell' className='tds-u-p2' colSpan={7}>{t('IngenDataAttVisa')}</td>
              </TdsTableBodyRow>}
              {filteredData.map((row, specIndex) => (
                <TdsTableBodyRowExpandable key={row.id}>
                  <TdsBodyCell cellKey='displayName'>{row.displayName}</TdsBodyCell>
                  <TdsBodyCell cellKey='description'>{row.description}</TdsBodyCell>
                  <div slot='expand-row' style={{display: 'flex', flexDirection: 'column'}}>
                    <div style={{display: 'flex', flexDirection: 'row-reverse'}}>
                    <TdsButton
                        variant='ghost'
                        size='sm'
                        id={`editSpecButton_${specIndex}`}
                        onClick={() => { setSelectedSpec(row); setEmissionSpecSelector(`editSpecButton_${specIndex}`); setIsModalVisible(true); }}
                      >
                        <TdsIcon slot='icon' size='16px' name='edit' />
                      </TdsButton>
                    {!(row.isScaniaSpecification) && <TdsButton
                        id={`deleteSpecButton_${specIndex}`}
                        variant='ghost'
                        size='sm'
                        onClick={() => { setSelectedSpec(row); setConfirmationSelector(`deleteSpecButton_${specIndex}`)}}
                      >
                        <TdsIcon slot='icon' size='16px' name='trash' />
                      </TdsButton>
                    }
                    </div>
                    <SpecDetailsTable 
                      spec={row} 
                      index={specIndex} 
                    />
                  </div>
                </TdsTableBodyRowExpandable>
              ))}
            </TdsTableBody>
          </TdsTable>
        </div>
      </>
      }
      {toastsToDisplay.length > 0 && (
        <ToastContainer toasts={toastsToDisplay} removeToast={removeToast} />
      )}
    </>
    );
};

export default EmissionSpecsTable;
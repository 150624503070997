import { applyPolyfills } from '@myscania/scope/dist/loader';
import { defineCustomElements as scope } from '@myscania/scope/dist/loader';

applyPolyfills().then(() => {
  scope();
});

const ThemeLoader = () => {
  return <>
    <scope-theme></scope-theme>
  </>;
};

export default ThemeLoader;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { uuidv4 } from '../../utils/api';

const X_CLIENT_ID = process.env.REACT_APP_X_CLIENT || 'vp';

export const emissionSpecsApi = createApi({
  reducerPath: 'emissionSpecsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_MR_BFF_HOST}/api`,
    prepareHeaders: (headers) => {
      const accessToken = sessionStorage.getItem('access_token');
      headers.set('Authorization', `Bearer ${accessToken}`);
      headers.set('X-Correlation-ID', uuidv4());
      headers.set('X-Client', X_CLIENT_ID);
      headers.set('content-type', 'application/json');
      return headers;
    },
  }),
  tagTypes: ['EMISSIONSPECS'],
  endpoints: (builder) => ({
    getEmissionSpecs: builder.query<EmissionSpecItem[], void>({
      query: () => '/v2/emission/emission/specifications',
      providesTags: ['EMISSIONSPECS'],
    }),
    getActiveCustomerVehicles: builder.query<any, void>({
      query: () => '/v1/emission/vehicles/active'
    }),
    updateEmissionSpec: builder.mutation<EmissionSpecItem, UpdateArgs_EmissionSpecItem>({
      query: (spec: UpdateArgs_EmissionSpecItem) => ({
        url: `/v2/emission/emission/specifications/${spec.id}`,
        method: 'PUT',
        body: JSON.stringify(spec),
      }),
      invalidatesTags: (_, error) => (error ? [] : ['EMISSIONSPECS']),
    }),
    createEmissionSpec: builder.mutation<EmissionSpecItem, CreateArgs_EmissionSpecItem>({
      query: (spec: CreateArgs_EmissionSpecItem) => ({
        url: '/v2/emission/emission/specifications',
        method: 'POST',
        body: JSON.stringify(spec),
      }),
      invalidatesTags: (_, error) => (error ? [] : ['EMISSIONSPECS']),
    }),
    deleteEmissionSpec: builder.mutation<boolean, number>({
      query: (emissionSpecId: number) => ({
        url: `/v2/emission/emission/specifications/${emissionSpecId}`,
        method: 'DELETE'
      }),
      invalidatesTags: (_, error) => (error ? [] : ['EMISSIONSPECS']),
    }),
  }),
});

export const {
    useGetEmissionSpecsQuery,
    useGetActiveCustomerVehiclesQuery,
    useUpdateEmissionSpecMutation,
    useCreateEmissionSpecMutation,
    useDeleteEmissionSpecMutation
} = emissionSpecsApi;
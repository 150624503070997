import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { TdsDropdown, TdsDropdownOption } from '@scania/tegel-react';

import '../../styles/GeneralStyles.css';

type Props = {
  selectedOptions: string[];
  onSelection: Dispatch<SetStateAction<string[]>>;
};

const VehicleTypeDropdown = ({ selectedOptions, onSelection }: Props) => {
  const { t } = useTranslation();
  const vehicleTypeDropdownRef = useRef<HTMLTdsDropdownElement>(null);

  const handleVehicleTypeSelection = useCallback(
    (e: any) => {
      let optionsCopy = [...selectedOptions];

      if (e.detail.selected && !optionsCopy.includes(e.detail.value)) {
        optionsCopy.push(e.detail.value);
      } else {
        optionsCopy = optionsCopy.filter((option) => option !== e.detail.value);
      }

      onSelection(optionsCopy);
    },
    [onSelection, selectedOptions]
  );

  useEffect(() => {
    const vehicleTypeDropdown = vehicleTypeDropdownRef.current;
    if (!vehicleTypeDropdown) return;

    vehicleTypeDropdown.addEventListener(
      'tdsSelect',
      handleVehicleTypeSelection
    );

    return () => {
      vehicleTypeDropdown.removeEventListener(
        'tdsSelect',
        handleVehicleTypeSelection
      );
    };
  }, [handleVehicleTypeSelection]);

  return (
    <div className='typeDropdownContainer'>
      <TdsDropdown
        ref={vehicleTypeDropdownRef}
        name='vehicleTypes'
        multiselect
        modeVariant='primary'
        size='md'
        placeholder={t('Välj')}
        label={t('TH_Fordonstyp')}
        label-position='outside'>
        <TdsDropdownOption key='truck' value='truck'>
          {t('Lastbil')}
        </TdsDropdownOption>
        <TdsDropdownOption key='bus' value='bus'>
          {t('Buss')}
        </TdsDropdownOption>
        <TdsDropdownOption key='engine' value='engine'>
          {t('Motor')}
        </TdsDropdownOption>
      </TdsDropdown>
    </div>
  );
};

export default VehicleTypeDropdown;

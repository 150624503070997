import { TdsButton } from '@scania/tegel-react'
import { useTranslation } from 'react-i18next'

import { redirectToOldVP } from '../../utils/preRelease'

const Banner = () => {
    const {t} = useTranslation()

    return (
        <scope-navbar-banner type="info" sticky heading={t('VP_IntroducingNewVP')} closeable="false">
            <TdsButton slot="actions" type="button" variant="secondary" size="sm" text={t('VP_GoBackToOldVersion')} onClick={redirectToOldVP}></TdsButton>
        </scope-navbar-banner>
    )
}

export default Banner
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { uuidv4 } from '../../utils/api';

const X_CLIENT_ID = process.env.REACT_APP_X_CLIENT || 'vp';
const DASHBOARD_PARAM_NAME = 'dashboard';
const CONSOLE_PARAM_NAME = 'console';

export const viewsApi = createApi({
  reducerPath: 'viewsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_VP_BFF_HOST}/api/v1`,
    prepareHeaders: (headers) => {
      const accessToken = sessionStorage.getItem('access_token');
      headers.set('Authorization', `Bearer ${accessToken}`);
      headers.set('X-Correlation-ID', uuidv4());
      headers.set('X-Client', X_CLIENT_ID);
      headers.set('content-type', 'application/json');
      return headers;
    },
  }),
  tagTypes: ['DASHBOARDS', 'SINGLE_DASHBOARD_SPECS'],
  endpoints: (builder) => ({
    getUserViews: builder.query({
      query: () => `/${DASHBOARD_PARAM_NAME}`,
      providesTags: ['DASHBOARDS'],
    }),
    getDashboardSpecification: builder.query({
      query: (dashboardReference: string) => `/${DASHBOARD_PARAM_NAME}/${dashboardReference}`,
      providesTags: ['SINGLE_DASHBOARD_SPECS'],
    }),
    createDashboard: builder.mutation({
      query: (dashboardDetails: DashboardDetails) => ({
        url: `/${DASHBOARD_PARAM_NAME}`,
        method: 'POST',
        body: JSON.stringify(dashboardDetails),
      }),
      invalidatesTags: (_, error) => (error ? [] : ['DASHBOARDS']),
    }),
    updateDashboard: builder.mutation({
      query: (updatedDashboard: DashboardDetails) => ({
        url: `/${DASHBOARD_PARAM_NAME}`,
        method: 'PUT',
        body: JSON.stringify(updatedDashboard)
      }),
      invalidatesTags: (_, error) => (error ? [] : ['DASHBOARDS', 'SINGLE_DASHBOARD_SPECS']),
    }),
    updateDefaultDashboard: builder.mutation({
      query: (dashboardRef: string) => ({
        url: `/${CONSOLE_PARAM_NAME}/defaultDashboard/${dashboardRef}`,
        method: 'PUT',
      }),
    }),
    deleteDashboard: builder.mutation({
      query: (dashboardRef: string) => ({
        url: `/${DASHBOARD_PARAM_NAME}/${dashboardRef}`,
        method: 'DELETE'
      }),
      invalidatesTags: (_, error) => (error ? [] : ['DASHBOARDS']),
    })
  }),
});

export const {
  useGetUserViewsQuery,
  useUpdateDefaultDashboardMutation,
  useCreateDashboardMutation,
  useUpdateDashboardMutation,
  useGetDashboardSpecificationQuery,
  useDeleteDashboardMutation
} = viewsApi;

import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TdsTooltip } from '@scania/tegel-react';
import {
  headerTitleToTranslationKey,
  vehicleIdentifierToTranslationKey,
} from '../../utils/report/convertTranslationKeys';
import {
  generateSubheaderLabel,
  stripNonAlphaCharacters,
} from '../../utils/report/tableUtils';
import styles from '../../styles/DataTableHeader.module.css';
import DoubleSortIcon from '../../assets/Sorting.svg';
import arrowUpIcon from '../../assets/arrowUp.svg';
import arrowDownIcon from '../../assets/arrowDown.svg';
import { groupsExplanationsKeys } from '../../utils/report/paramsGroups';

type DataTableHeaderProps = {
  vehicleIdentifier: string;
  propulsionConsumptionUnit: string;
  firstRowTableDataContent: {
    identifier: EquipmentInfo;
    columns: Array<OverviewEquipmentGroup>;
  };
  sortTableContent: Function;
};

const DataTableHeader = ({
  vehicleIdentifier,
  propulsionConsumptionUnit,
  firstRowTableDataContent,
  sortTableContent,
}: DataTableHeaderProps) => {
  const { t } = useTranslation();
  const [sortField, setSortField] = useState('');
  const [order, setOrder] = useState('asc');
  const [hoveredSubth, setHoveredSubth] = useState('');
  const dashboardGroups =
    firstRowTableDataContent?.columns?.map((group: any) => group.columnName) || [];
  const userUnitsMeasureSystem = propulsionConsumptionUnit.includes('miles')
    ? 'imperial'
    : 'metric';

  const handleSortingChange = (accessor: string) => {
    const sortOrder =
      accessor === sortField && order === 'asc' ? 'desc' : 'asc';
    setSortField(accessor);
    setOrder(sortOrder);
    sortTableContent(accessor, sortOrder);
  };

  const hoverFirstUnitChild = (cellIndex: number) => {
    setHoveredSubth(`tableSubHeader${cellIndex}_1`);
  };

  const unhoverFirstUnitChild = () => {
    setHoveredSubth('');
  };

  return (
    <thead>
      <tr>
        <th
          id='tableHeader_1'
          key='keyTableHeader1'
          className={`${
            styles.firstRowHeaderCell
          } leftAlignedText stickyTopHeaderParam firstColumnZIndex ${
            styles.stickyLeft
          } ${sortField !== vehicleIdentifier && styles.hoverableHeader} ${
            sortField === vehicleIdentifier && styles.sortedGroupHeader
          }`}
          onMouseOver={() => hoverFirstUnitChild(1)}
          onMouseOut={() => unhoverFirstUnitChild()}>
          <div className={styles.thContent}>
            <div>{t('Utrustning')}</div>
          </div>
        </th>
        {dashboardGroups.map((columnName: string, index: number) => {
          const currentGroup = firstRowTableDataContent?.columns?.find((group: any) => group.columnName === columnName)
          return currentGroup ? (
            <th
              id={`tableHeader${index + 2}`}
              key={`keyTableHeader${index + 2}`}
              colSpan={currentGroup?.subColumns.length}
              className={`${
                styles.firstRowHeaderCell
              } leftAlignedText stickyTopHeaderParam ${styles.normalColWidth} ${
                !(
                  sortField.includes(columnName) &&
                  sortField.length === columnName.length + 2
                ) && styles.hoverableHeader
              } ${
                sortField.includes(columnName) &&
                sortField.length === columnName.length + 2 &&
                styles.sortedGroupHeader
              }`}
              onMouseEnter={() => hoverFirstUnitChild(index + 2)}
              onMouseLeave={() => unhoverFirstUnitChild()}>
              <div className={`${styles.thContent} fullWidth`}>
                <div
                  className={`fullWidth ${styles.overflowTh}`}
                  id={`overflowingTableHeader${index + 2}`}>
                  {stripNonAlphaCharacters(
                    t(headerTitleToTranslationKey(columnName))
                  )}
                </div>
                <TdsTooltip
                  placement='top'
                  selector={`#tableHeader${index + 2}`}
                  className={styles.headerTooltips}
                  text=''>
                  <div>
                    <b>
                      {stripNonAlphaCharacters(
                        t(headerTitleToTranslationKey(columnName))
                      )}
                    </b>
                    <br />
                    {(groupsExplanationsKeys[columnName] &&
                      groupsExplanationsKeys[columnName][userUnitsMeasureSystem]) ?
                        t(groupsExplanationsKeys[columnName][userUnitsMeasureSystem]) : ''}
                  </div>
                </TdsTooltip>
              </div>
            </th>
          ) : <th></th>
        })}
      </tr>
      <tr>
        <th
          key='keyTableSubHeader1_1'
          id='tableSubHeader1_1'
          className={`leftAlignedText firstColumnZIndex stickyTopUnit ${
            styles.stickyLeft
          } ${styles.subTh} ${styles.hoverable} ${
            sortField === vehicleIdentifier ? styles.sortedColumn : ''
          } ${
            hoveredSubth === 'tableSubHeader1_1' &&
            sortField !== vehicleIdentifier
              ? styles.hoveredSubth
              : ''
          }`}
          onClick={() => handleSortingChange(vehicleIdentifier)}>
          <div className={styles.thContent}>
            <div className={styles.leftAlignedText}>
              {t(vehicleIdentifierToTranslationKey(vehicleIdentifier))}
              <span className={styles.beforeIcons}>
                <img src={DoubleSortIcon} alt={t('Sortera')} width={14} />
              </span>
              {sortField === vehicleIdentifier && (
                <span className={styles.iconsContainer}>
                  {order === 'desc' && (
                    <img src={arrowDownIcon} alt={t('Sortera')} width={14} />
                  )}
                  {order === 'asc' && (
                    <img src={arrowUpIcon} alt={t('Sortera')} width={14} />
                  )}
                </span>
              )}
            </div>
          </div>
        </th>
        {dashboardGroups.map(
          (columnName: string, index: number) => {
            const currentGroup = firstRowTableDataContent?.columns?.find((group: any) => group.columnName === columnName)

            return (
              typeof currentGroup !== 'undefined' && currentGroup.subColumns?.map(
              (
                metric: Metric,
                unitIndex: number
              ) => (
                <th
                  key={`keyTableSubHeader${index + 2}_${unitIndex + 1}`}
                  id={`tableSubHeader${index + 2}_${unitIndex + 1}`}
                  className={`rightAlignedText stickyTopUnit ${styles.subTh} ${
                    styles.hoverable
                  } ${
                    sortField === metric.name ? styles.sortedColumn : ''
                  } ${
                    hoveredSubth === `tableSubHeader${index + 2}_1` &&
                    unitIndex === 0 &&
                    sortField !== `${columnName}_0`
                      ? styles.hoveredSubth
                      : ''
                  }`}
                  onClick={() =>
                    handleSortingChange(`${columnName}_${unitIndex}`)
                  }>
                  <div className={styles.thContent}>
                    <div>
                      {sortField !== `${columnName}_${unitIndex}` && (
                        <span className={styles.beforeIcons}>
                          <img
                            src={DoubleSortIcon}
                            alt={t('Sortera')}
                            width={14}
                          />
                        </span>
                      )}
                      {sortField === `${columnName}_${unitIndex}` && (
                        <span className={styles.iconsContainer}>
                          {order === 'desc' && (
                            <img
                              src={arrowDownIcon}
                              alt={t('Sortera')}
                              width={14}
                            />
                          )}
                          {order === 'asc' && (
                            <img
                              src={arrowUpIcon}
                              alt={t('Sortera')}
                              width={14}
                            />
                          )}
                        </span>
                      )}
                      {generateSubheaderLabel(metric)}
                    </div>
                  </div>
                </th>
              )
            )
            )
          }
        )}
      </tr>
    </thead>
  );
};

export default DataTableHeader;

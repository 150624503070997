import {
    TdsBodyCell,
    TdsHeaderCell,
    TdsTable,
    TdsTableBody,
    TdsTableBodyRow,
    TdsTableHeader
} from "@scania/tegel-react";
import { useTranslation } from "react-i18next";

const tableStructure = (spec?: EmissionSpecItem) => {
  const { cO2 = 0, co = 0, pm = 0, nox = 0, hc = 0 } = spec ?? {};
  return [
      { header: 'CO2', value: cO2, name: 'cO2' },
      { header: 'CO', value: co, name: 'co' },
      { header: 'NOx', value: nox, name: 'nox' },
      { header: 'PM', value: pm, name: 'pm'},
      { header: 'HC', value: hc, name: 'hc' }
  ]
}

type SpecDetailsTableProps = {
  spec?: EmissionSpecItem;
  index: number;
}

const SpecDetailsTable = ({spec, index}: SpecDetailsTableProps) => {
  const { t } = useTranslation();
  return (
    <TdsTable tableId={`emissionSpecDetails-${index}`} noMinWidth responsive>
      <TdsTableHeader>
        {tableStructure(spec).map((field, fieldIndex) => (
          <TdsHeaderCell key={`header-cell-${index}-${fieldIndex}`} cellKey={field.header} cellValue={t(field.header)} />
        ))}
      </TdsTableHeader>
      <TdsTableBody>
        <TdsTableBodyRow>
          {tableStructure(spec).map((field, fieldIndex) => (
            <TdsBodyCell key={`field-${index}-${fieldIndex}`}>
              {Number(field.value).toLocaleString()}
            </TdsBodyCell>
          ))}
        </TdsTableBodyRow>
      </TdsTableBody>
    </TdsTable>
  )
}

export default SpecDetailsTable;
import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { TdsDropdown, TdsDropdownOption } from '@scania/tegel-react';
import { TdsDropdownCustomEvent } from '@scania/tegel';

type Props = {
  intervalType: string;
  changeIntervalType: any;
};

const IntervalPicker = ({ intervalType, changeIntervalType }: Props) => {
  const { t } = useTranslation();
  const selectIntervalRef = useRef<HTMLTdsDropdownElement>(null);

  const handleIntervalSelection = useCallback(
    (e: TdsDropdownCustomEvent<{ name: string; value: string }>) => {
      changeIntervalType(e.detail.value);
    },
    [changeIntervalType]
  );

  useEffect(() => {
    const intervalDropdown = selectIntervalRef.current;
    if (!intervalDropdown) return;

    intervalDropdown?.setValue(intervalType);
  }, [intervalType]);

  useEffect(() => {
    const intervalDropdown = selectIntervalRef.current;
    if (!intervalDropdown) return;

    intervalDropdown.addEventListener('tdsChange', handleIntervalSelection);

    return () => {
      intervalDropdown.removeEventListener(
        'tdsChange',
        handleIntervalSelection
      );
    };
  }, [handleIntervalSelection]);

  return (
    <TdsDropdown
      ref={selectIntervalRef}
      name='intervalPicker'
      placeholder={t('Välj')}
      size='md'
      defaultValue='custom'
      modeVariant='secondary'
      style={{ height: '54px', width: '200px' }}>
      <TdsDropdownOption key='custom' value='custom'>
        {t('AnpassatIntervall')}
      </TdsDropdownOption>
      <TdsDropdownOption key='previousWeek' value='previousWeek'>
        {t('FöregåendeVecka')}
      </TdsDropdownOption>
    </TdsDropdown>
  );
};

export default IntervalPicker;

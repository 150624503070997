import { useRef } from 'react';
import { TdsToast } from '@scania/tegel-react';

import style from '../styles/Toast.module.css';

type Props = {
  toasts: Array<Toast>;
  removeToast: Function;
};

const ToastContainer = ({ toasts, removeToast }: Props) => {
  const toastRef = useRef<HTMLTdsToastElement>(null);

  const handleRemoveToast = (id: string | number) => {
    removeToast(id);
  };

  return (
    <div className={style.toastContainer}>
      {toasts.map((toast) => (
        <TdsToast
          ref={toastRef}
          key={toast.id}
          className={style.tdsToast}
          variant={toast.variant}
          header={toast.header}
          subheader={toast.subheader}
          onTdsClose={() => handleRemoveToast(toast.id)}
        />
      ))}
    </div>
  );
};

export default ToastContainer;

export const getEnvironmentShortDesc = () => {
  switch (process.env.REACT_APP_APP_ENV) {
    case "development":
      return "dev";
    case "test":
      return "test";
    case "production":
      return "prod";
    default:
      return "dev";
  }
};

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { formatDateToUTC, uuidv4 } from '../../utils/api';

const X_CLIENT_ID = process.env.REACT_APP_X_CLIENT || '';

export const fuelReportApi = createApi({
  reducerPath: 'fuelReportApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_FUEL_REPORT_HOST,
    prepareHeaders: (headers) => {
      const accessToken = sessionStorage.getItem('access_token');
      headers.set('Authorization', `Bearer ${accessToken}`);
      headers.set('X-Correlation-ID', uuidv4());
      headers.set('X-Client', X_CLIENT_ID);
      headers.set('content-type', 'application/json');
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getFuelReport: builder.query<FuelReportItem[], any>({
      query: ({ startTime, endTime, language }) => ({
        url: '',
        params: {
          startTime: formatDateToUTC(startTime),
          endTime: formatDateToUTC(endTime),
          language: language
        }
      }),
    }),
  }),
});

export const { useGetFuelReportQuery } = fuelReportApi;

import i18n from '../localization'
import { getEquipmentTypeTranslationKey, getFuelTypeTranslationKey, getPropulsionTranslationKey, unitToTranslationKey } from './convertTranslationKeys';

const propulsions = ['combustionengine', 'electricmotor', 'hybrid'];
const fuelTypes = ['diesel', 'ethanol', 'gas', 'electric'];
const equipmentTypes = ['truck', 'bus', 'engine', 'other'];
const unitsToBeRoundedToInteger = ['km', 'kilometers', 'miles', 'liters', 'litres', 'litre', 'liter', 'gallons', 'kg', 'kilograms', 'nm3'];
const manuallyRoundedParamNames = ['custom.calculated.driver_evaluation.score.average'];

const getTranslatedInfoMetricValue = (infoMetricValue: string) => {
  if (propulsions.includes(infoMetricValue?.toLowerCase())) {
    return i18n.t(getPropulsionTranslationKey(infoMetricValue));
  }
  if (fuelTypes.includes(infoMetricValue?.toLowerCase())) {
    return i18n.t(getFuelTypeTranslationKey(infoMetricValue));
  }
  if (equipmentTypes.includes(infoMetricValue?.toLowerCase())) {
    return i18n.t(getEquipmentTypeTranslationKey(infoMetricValue));
  }

  return infoMetricValue;
}

export const converttoTime = (numberOfSeconds: any) => {
  if (Number.isNaN(numberOfSeconds) || numberOfSeconds === 0) {
    return '00:00'
  }
  const d = Number(numberOfSeconds);
  const h = Math.floor(d / 3600);
  const m = Math.floor(d % 3600 / 60);

  const hDisplay = h > 0 ? h : '00'
  const mDisplay = m > 0 ? (m < 10 ? '0' + m : m) : '00'
  return `${hDisplay}:${mDisplay}`
}

export const stripBraces = (stringToModify: string = '') => {
  return stringToModify?.replace('(', '')?.replace(')', '')
}

export const displayFormattedValue = (metric: Metric) => {
  return metric.value === undefined || metric.value === null
    ? '-'
    : Number.isNaN(Number(metric.value))
      ? (propulsions.includes(metric.value.toLowerCase())
        ? i18n.t(getPropulsionTranslationKey(metric.value))
        : (fuelTypes.includes(metric.value.toLowerCase())
          ? i18n.t(getFuelTypeTranslationKey(metric.value))
          : (equipmentTypes.includes(metric.value.toLowerCase())
            ? i18n.t(getEquipmentTypeTranslationKey(metric.value))
            : metric.value)))
      : Number.isInteger(Number(metric.value))
        ? (metric.unit === 'minutes' || metric.unit === 'seconds')
          ? converttoTime(metric.value)
          : Number(metric.value)
        : (unitsToBeRoundedToInteger.includes((metric.unit || '').toLowerCase()) || manuallyRoundedParamNames.includes(metric.name.toLowerCase())
          ? Math.round(Number(metric.value))
          : Number(metric.value.toFixed(2)));
}

export const stripNonAlphaCharacters = (stringToModify: string = '') => {
  return stringToModify.replace('(', '')
    .replace(')', '')
    .replace(',', '')
    .replace('/', '')
    .replace('0', '')
    .replace('1', '')
    .replace('2', '')
    .replace('3', '')
    .replace('{', '')
    .replace('}', '')
    .replace('{}', '');
}

export const getMatchingPropForVehicleName = (vehicleIdentifier: string = ''): 'registrationNumber' | 'chassisNumber' | 'alias' => {
  switch (vehicleIdentifier) {
    case 'Registration number': return 'registrationNumber'
    case 'Chassi number': return 'chassisNumber'
    case 'Vehicle alias': return 'alias'
    default: return 'chassisNumber'
  }
}

export const getAvailableIdentifier = (vehicleIdentifier: string, equipmentInfo: { alias: string, chassisNumber: string, registrationNumber: string}) => {
  switch (vehicleIdentifier) {
    case 'registrationNumber': return equipmentInfo[vehicleIdentifier] || equipmentInfo.alias || equipmentInfo.chassisNumber || '-';
    case 'chassisNumber': return equipmentInfo[vehicleIdentifier] || equipmentInfo.alias || equipmentInfo.registrationNumber || '-';
    default: return equipmentInfo.alias || equipmentInfo.registrationNumber || equipmentInfo.chassisNumber || '-';
  }
}

export const transformToAcceptedGroupPayload = (selectedGroupOption: string = 'all') => {
  switch (selectedGroupOption) {
    case 'groupFilterAll': return undefined;
    case 'all': return undefined;
    case 'groupFilterNoAffiliation': return 'none';
    default: return selectedGroupOption;
  }
}

export const getCurrentlySelectedDashboard = (dashboards: View[]) => {
  for (const singleDashboard of dashboards) {
    if (singleDashboard.isDefault) return singleDashboard;
  }
  return dashboards[0];
}

export const getParamColumnNameForStringSubcolumns = (subcolumnName: string = '') => {
  // the groups containing subColumns with strings instead of units are either equipment_info or support_score
  if (subcolumnName.includes('info')) { return 'equipment_information'; }
  return 'support_score';
}

export const sortRows = (
  sortField: string,
  sortOrder: string,
  tableData: Array<WidgetEquipment>
) => {
  const identifierPossibilities = [
    'alias',
    'registrationNumber',
    'chassisNumber',
  ];

  const sorted = [...tableData].sort((a: WidgetEquipment, b: WidgetEquipment) => {
    let itemA, itemB;
    if (identifierPossibilities.includes(sortField)) {
      itemA = getAvailableIdentifier(sortField, a.identifier);
      itemB = getAvailableIdentifier(sortField, b.identifier);
    } else
    if (sortField.includes('info') || sortField.includes('score')) {
      const groupForParam = getParamColumnNameForStringSubcolumns(sortField);
      const indexOfGroup = a.columns.findIndex(group => group.columnName === groupForParam);
      const indexOfUnit = Number(sortField.substring(sortField.length - 1));
      
      // prepare the values to be compared
      if (groupForParam.toLowerCase().includes('info')) {
        // we are going to compare and sort 2 translated strings for info metrics
        itemA = getTranslatedInfoMetricValue(a.columns[indexOfGroup].subColumns[indexOfUnit].value);
        itemB = getTranslatedInfoMetricValue(b.columns[indexOfGroup].subColumns[indexOfUnit].value);
      }
      else {
        itemA = a.columns[indexOfGroup].subColumns[indexOfUnit].value;
        itemB = b.columns[indexOfGroup].subColumns[indexOfUnit].value;
      }
    } else {
      const groupForParam = sortField.substring(0, sortField.length - 2);
      const indexOfUnit = parseInt(sortField.substring(sortField.length - 1));
      const indexOfGroup = a.columns.findIndex(group => group.columnName === groupForParam);
      
      itemA = a.columns[indexOfGroup].subColumns[indexOfUnit].value;
      itemB = b.columns[indexOfGroup].subColumns[indexOfUnit].value;
    }

    if (itemA === null && itemB === null) return 0;
    if (itemA === null) {
      return sortOrder === 'asc' ? -1 : 1;
    }
    if (itemB === null) {
      return sortOrder === 'asc' ? 1 : -1;
    }

    if (itemA === itemB) return 0;

    if (typeof itemA === 'number' && typeof itemB === 'number') {
      return itemA > itemB
        ? sortOrder === 'asc'
          ? 1
          : -1
        : sortOrder === 'asc'
        ? -1
        : 1;
    } else {
      return (
        itemA.toString().localeCompare(itemB.toString(), 'en') *
        (sortOrder === 'asc' ? 1 : -1)
      );
    }
  });

  const updatedTableData = [...sorted];

  return updatedTableData;
};

export const createDeepCopy = (obj: any): any => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (obj instanceof Date) {
    let copy = new Date();
    copy.setTime(obj.getTime());
    return copy;
  }

  if (obj instanceof Array) {
    let copy = [];
    for (let i = 0; i < obj.length; i++) {
      copy[i] = createDeepCopy(obj[i]);
    }
    return copy;
  }

  if (obj instanceof Object) {
    let copy: any = {};
    for (let attr in obj) {
      if (obj.hasOwnProperty(attr)) {
        copy[attr] = createDeepCopy(obj[attr]);
      }
    }
    return copy;
  }
};

export const generateUnitSubheaderText = (unit: string) => {
  let propulsionType: string = '';

  if (unit?.toLowerCase()?.includes('kg') || unit?.toLowerCase()?.includes('nm3')) {
    propulsionType = i18n.t('Gas');
  }

  if (unit?.toLowerCase()?.includes('kwh')) {
    propulsionType = i18n.t('Electricitet');
  }

  if (propulsionType) {
    return `${propulsionType.toLowerCase()} (${stripBraces(i18n.t(unitToTranslationKey(unit)))})`;
  }

  return stripBraces(i18n.t(unitToTranslationKey(unit))) || '-';
};

export const generateInfoMetricSubheaderText = (metricName: string = '') => {
  switch (metricName) {
    case 'info.equipment.brand': return i18n.t('Tillverkare').toLowerCase();
    case 'info.equipment.equipment_type': return i18n.t('Utrustningstyp').toLowerCase();
    case 'info.equipment.model': return i18n.t('Modell').toLowerCase();
    case 'info.equipment.engine_designation': return i18n.t('TH_Motor').toLowerCase();
    case 'info.equipment.fuel_type': return i18n.t('Bränsletyp').toLowerCase();
    case 'info.equipment.propulsion_system': return i18n.t('DE_PropulsionType').toLowerCase();
    default: return i18n.t(metricName).toLowerCase();
  }
}

export const generateSupportScoreMetricSubheaderText = (metricName: string = '') => {
  switch (metricName) {
    case 'custom.calculated.driver_evaluation.score.average': return i18n.t('GenomsnittspoängI_').toLowerCase();
    case 'custom.calculated.driver_evaluation.score.anticipation': return i18n.t('Förutseende___').toLowerCase();
    case 'custom.calculated.driver_evaluation.score.hill': return i18n.t('Backkörning___').toLowerCase();
    case 'custom.calculated.driver_evaluation.score.gear_selection': return `${i18n.t('Växelval')} (${stripNonAlphaCharacters(i18n.t('_0_Procent'))})`.toLowerCase();
    case 'custom.calculated.driver_evaluation.score.hybrid_brake': return `${i18n.t('VP_RegenerativeBrake')} (${stripNonAlphaCharacters(i18n.t('_0_Procent'))})`.toLowerCase();
    case 'custom.calculated.driver_evaluation.score.wear': return `${i18n.t('Bromsanvändning')} (${stripNonAlphaCharacters(i18n.t('_0_Procent'))})`.toLowerCase();
    default: return i18n.t(metricName).toLowerCase();
  }
}

export const generateSubheaderLabel = (metric: Metric = { name: '', unit: '', value: null }) => {
  if (metric) {
    // the metric is part of the equipment_information group
    if (metric.name && metric.name.includes('info.equipment')) {
      return generateInfoMetricSubheaderText(metric.name);
    }
    // the metric is part of the support_score group
    if (metric.name && metric.name.includes('driver_evaluation.score')) {
      return generateSupportScoreMetricSubheaderText(metric.name);
    }
    // the metric is neither equipment_info nor support_score metric
    if (metric.unit) {
      return generateUnitSubheaderText(metric.unit);
    }

    // the unit is undefined
    return '-';
  }

  return '-';
}

export const adjustOdometerToProfileSetting = (odometerInMeters: number, propulsionConsumptionUnit: string = 'kmperprop') => {
  if (odometerInMeters === null || odometerInMeters === undefined) {
    return '-';
  }

  if (propulsionConsumptionUnit.includes('miles')) {
    // tranform from meters to miles
    return Math.round(odometerInMeters * 0.000621371).toLocaleString();
  }

  // transform from meters to km
  return Math.round(odometerInMeters / 1000).toLocaleString();
}

export const displayAvailableDriverIdentifier = (driverDetails: any) => {
  if (driverDetails.staffDriver) {
    return driverDetails.staffDriver.firstName + ' ' + driverDetails.staffDriver.lastName;
  }
  if (driverDetails.identification) return driverDetails.identification;
  return 'OkändaFörar_Id';
}

export const getSortParameter = (vehicleData: WidgetEquipment | null, sortIdentifier: string) => {
  if (!vehicleData) return '';
  
  const identifierPossibilities = [
    'alias',
    'registrationNumber',
    'chassisNumber',
  ];

  if (identifierPossibilities.includes(sortIdentifier)) return 'driverName';

  const groupForParam = sortIdentifier.substring(0, sortIdentifier.length - 2);
  const indexOfUnit = parseInt(sortIdentifier.substring(sortIdentifier.length - 1));
  const indexOfGroup = vehicleData.columns.findIndex(group => group.columnName === groupForParam);

  return vehicleData?.columns[indexOfGroup]?.subColumns[indexOfUnit]?.name || '';
}

export const sortDriverData = (driverData: any, sortParameter: string, sortOrder: string) => {
  if (!sortParameter.length) return driverData;

  const indexOfParam = driverData[0].parameters.findIndex((element: { name: string; }) => element.name.toLowerCase() === sortParameter);

  const sorted = [...driverData].sort((a: any, b: any) => {
    let itemA, itemB;
    if (sortParameter === 'driverName') {
      itemA = i18n.t(displayAvailableDriverIdentifier(a));
      itemB = i18n.t(displayAvailableDriverIdentifier(b));
    } else {
      if (sortParameter.toLowerCase().includes('info')) {
        // we are going to compare and sort 2 translated strings for info metrics
        itemA = getTranslatedInfoMetricValue(a.parameters[indexOfParam].value);
        itemB = getTranslatedInfoMetricValue(b.parameters[indexOfParam].value);
      }
      else {
        itemA = a.parameters[indexOfParam].value;
        itemB = b.parameters[indexOfParam].value;
      }
    }

    if (itemA === null && itemB === null) return 0;
    if (itemA === null) {
      return sortOrder === 'asc' ? -1 : 1;
    }
    if (itemB === null) {
      return sortOrder === 'asc' ? 1 : -1;
    }

    if (itemA === itemB) return 0;

    if (typeof itemA === 'number' && typeof itemB === 'number') {
      return itemA > itemB
        ? sortOrder === 'asc'
          ? 1
          : -1
        : sortOrder === 'asc'
        ? -1
        : 1;
    } else {
      return (
        itemA.toString().localeCompare(itemB.toString(), 'en') *
        (sortOrder === 'asc' ? 1 : -1)
      );
    }
  });

  return sorted;
}

import * as Yup from 'yup';
import i18n from './localization'
 
const DISPLAY_NAME_MAX_LENGTH = 60;
const DESCRIPTION_MAX_LENGTH = 200;
 
export const emissionSpecsSchema = Yup.object().shape({
  displayName: Yup.string()
  .transform((value, originalValue) => originalValue === '' ? null : value)
  .required(i18n.t('_Obligatorisk'))
  .max(DISPLAY_NAME_MAX_LENGTH, i18n.t('MaxAntalTeckenUppnått')), // not reached because of the tdsTextField maxLength prop but keeping it for consistency of validation
  description: Yup.string()
  .max(DESCRIPTION_MAX_LENGTH, i18n.t('MaxAntalTeckenUppnått')), // not reached because of the tdsTextarea maxLength prop but keeping it for consitency of validation
  nox: Yup.number().required(i18n.t('_Obligatorisk')).min(0, i18n.t('DuMåsteAngeEttPositivtVärde')).typeError(i18n.t('AngeEttNumerisktVärde')),
  cO2: Yup.number().required(i18n.t('_Obligatorisk')).min(0, i18n.t('DuMåsteAngeEttPositivtVärde')).typeError(i18n.t('AngeEttNumerisktVärde')),
  hc: Yup.number().required(i18n.t('_Obligatorisk')).min(0, i18n.t('DuMåsteAngeEttPositivtVärde')).typeError(i18n.t('AngeEttNumerisktVärde')),
  co: Yup.number().required(i18n.t('_Obligatorisk')).min(0, i18n.t('DuMåsteAngeEttPositivtVärde')).typeError(i18n.t('AngeEttNumerisktVärde')),
  pm: Yup.number().required(i18n.t('_Obligatorisk')).min(0, i18n.t('DuMåsteAngeEttPositivtVärde')).typeError(i18n.t('AngeEttNumerisktVärde')),
  connectedEquipments: Yup.string()
})

export const getDisconnectedEquipments = (oldConnectedEquipments: string[] = [], newConnectedEquipments: string[] = []) => {
  if (oldConnectedEquipments?.length) {
    if (newConnectedEquipments.length === 0) {
      return oldConnectedEquipments;
    }
    // we have had some connected equipments and we need to see if they are still present in the current selected options for associated vehicles
    const currentDisconnectedEquipments: string[] = [];
    oldConnectedEquipments.forEach(singleEquipmentRef => {
      if (!newConnectedEquipments.includes(singleEquipmentRef)) {
        currentDisconnectedEquipments.push(singleEquipmentRef);
      }
    });

    return currentDisconnectedEquipments;
  }

  return [];
}
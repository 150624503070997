import { useRef, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TdsAccordion,
  TdsAccordionItem,
  TdsButton,
  TdsIcon,
  TdsLink,
  TdsMessage,
  TdsModal,
  TdsSpinner,
  TdsTextField,
} from '@scania/tegel-react';
import { skipToken } from '@reduxjs/toolkit/query';

import { useCreateDashboardMutation, useDeleteDashboardMutation, useGetDashboardSpecificationQuery, useUpdateDashboardMutation } from '../../state/views/query';
import { groupParams } from '../../utils/report/paramsGroups';
import { stripNonAlphaCharacters } from '../../utils/report/tableUtils';
import { getNameTranslationKey } from '../../utils/report/convertTranslationKeys';
import { allGroupsFullySelected, getGroupsCountPerCategory, isAccordionItemExpanded, updateParamsList } from '../../utils/views';
import ParameterGroupsContainer from '../containers/ParameterGroupsContainer';
import styles from '../../styles/ViewModal.module.css';
import ConfirmationModal from '../common/ConfirmationModal';
import { generateSecureNumber } from '../../utils/general';

type ViewModalProps = {
  header: string;
  selector: string;
  size: ModalSize;
  propulsionConsumptionUnit: string;
  gasConsumptionUnit: string;
  dashboardReference: string;
  pushNotification: Function;
  updateSelectedView: Function;
  setSelector: Function;
  refetchEquipments: Function;
};

const ViewModal = ({
  header,
  selector,
  size,
  propulsionConsumptionUnit,
  gasConsumptionUnit,
  dashboardReference = '',
  pushNotification,
  updateSelectedView,
  setSelector,
  refetchEquipments
}: ViewModalProps) => {
  const { t } = useTranslation();
  const [createDashboard, { isLoading: isSavingDashboard }] = useCreateDashboardMutation();
  const [updateDashboard, { isLoading: isUpdatingDashboard }] = useUpdateDashboardMutation();
  const [deleteDashboard] = useDeleteDashboardMutation();
  const [confirmationSelector, setConfirmationSelector] = useState<string>('')
  const [viewNameValue, setViewNameValue] = useState<string>('');
  const [searchedText, setSearchedText] = useState<string>('');
  const [viewNameError, setViewNameError] = useState(false);
  const [viewMetrics, setViewMetrics] = useState<Array<{
    columnName: string;
    subColumns: Array<string>
  }>>([]);
  const [visibleCategories, setVisibleCategories] = useState(Array(groupParams.length).fill(0, 0));
  const [paramsSelected, setParamsSelected] = useState(false);

  const viewNameElement = useRef<HTMLTdsTextFieldElement>(null);
  const viewModalElement = useRef<HTMLTdsModalElement>(null);
  const searchInputRef = useRef<HTMLTdsTextFieldElement>(null);  

  const {
    data: dashboardSpecs,
    isSuccess: dashboardSpecsLoadedSuccessfully,
    isError: isErrorLoadingDashboardSpecs,
    isFetching: isDashboardSpecsFetching,
    isLoading: isDashboardSpecsLoading,
    isUninitialized: isDashboardSpecsUninitialized,
    refetch: refetchDashboardSpecs
  } = useGetDashboardSpecificationQuery(
    (selector === 'editViewButton' && dashboardReference?.length) ? dashboardReference : skipToken
  );

  const handleNameChange = useCallback(
    (e: any) => {
      const currentViewName = e.detail?.target?.value?.trim() || '';
      setViewNameValue(currentViewName);
      const errorStatus = !(currentViewName.length > 0);
      if (errorStatus !== viewNameError) {
        setViewNameError(errorStatus);
      }
    },
    [viewNameError]
  );

  const handleSearchInputUpdate = useCallback(
    (e: any) => {
      const currentSearchedText = e.detail?.target?.value?.trim() || '';
      setSearchedText(currentSearchedText);
    }, []
  );

  const clearViewData = useCallback(() => {
    setViewMetrics([]);
    setViewNameValue('');
    setSelector('');
    setSearchedText('');
    setParamsSelected(false);

    if (viewNameError) {
      setViewNameError(false);
    }

    // collapse all accordion items
    document
      .querySelectorAll(`tds-accordion-item`)
      .forEach((accordionItem: HTMLTdsAccordionItemElement | any) => {
        accordionItem.expanded = false;
      });
  }, [viewNameError, setSelector]);

  useEffect(() => {
    const nameTextField = viewNameElement.current;
    if (!nameTextField) return;

    nameTextField.addEventListener('tdsInput', handleNameChange);

    return () => {
      nameTextField.removeEventListener('tdsInput', handleNameChange);
    };
  }, [handleNameChange]);

  useEffect(() => {
    const searchTextField = searchInputRef.current;
    if (!searchTextField) return;

    searchTextField.addEventListener('tdsInput', handleSearchInputUpdate);

    return () => {
      searchTextField.removeEventListener('tdsInput', handleSearchInputUpdate);
    };
  }, [handleSearchInputUpdate]);

  useEffect(() => {
    const viewModal = viewModalElement.current;
    viewModal?.addEventListener('tdsClose', clearViewData);
    return () => viewModal?.removeEventListener('tdsClose', clearViewData);
  }, [clearViewData]);

  useEffect(() => {
    if(selector === 'editViewButton' && dashboardReference.length > 0 && isDashboardSpecsUninitialized) {
      refetchDashboardSpecs()
    }
  }, [dashboardReference, isDashboardSpecsUninitialized, refetchDashboardSpecs, selector])

  useEffect(() => {
    if (selector.length > 0 &&
      selector === 'editViewButton' &&
      dashboardSpecsLoadedSuccessfully &&
      !isDashboardSpecsFetching &&
      !isDashboardSpecsLoading &&
      dashboardSpecs.title) {
      setViewNameValue(dashboardSpecs.title?.text || '');
      setViewMetrics(dashboardSpecs.widgets[0]?.properties?.columns || []);
    }
  }, [dashboardSpecs, dashboardSpecsLoadedSuccessfully, isDashboardSpecsFetching, isDashboardSpecsLoading, selector]);

  const updateViewParams = (
    newCheckedStatus: boolean,
    checkboxValue: string,
    checkboxName: string,
    isParentGroup: boolean
  ) => {
    const updatedList = updateParamsList(
      newCheckedStatus,
      checkboxValue,
      checkboxName,
      isParentGroup,
      viewMetrics,
      groupParams
    );

    setViewMetrics(updatedList);
  };

  const markAllCategoryGroups = (categoryIndex: number, newCheckedStatus: boolean) => {
    const allCategoryCheckboxes = document.querySelectorAll(`tds-checkbox[id^=category_${categoryIndex}_checkbox]`);
    let updatedViewMetrics = [...viewMetrics];

    allCategoryCheckboxes.forEach((parentCheckbox: HTMLTdsCheckboxElement | any) => {
      // there is one special case where not all children checkboxes are checked so parent is checked but indeterminate
      // we need to toggle all unchecked children checkboxes
      if (newCheckedStatus && parentCheckbox.checked && parentCheckbox.indeterminate) {
        const allChildrenCheckboxes = document.querySelectorAll(`tds-checkbox[name^=${parentCheckbox.name}__]`);
        allChildrenCheckboxes.forEach((childCheckbox: HTMLTdsCheckboxElement | any) => {
          if (childCheckbox.checked !== newCheckedStatus) {
            childCheckbox.toggleCheckbox();
            updatedViewMetrics = updateParamsList(
              newCheckedStatus,
              childCheckbox.value,
              childCheckbox.name,
              false,
              updatedViewMetrics,
              groupParams
            );
          }
        })
      }
      
      if (parentCheckbox.checked !== newCheckedStatus) {
        parentCheckbox.toggleCheckbox();
        updatedViewMetrics = updateParamsList(
          newCheckedStatus,
          parentCheckbox.value,
          parentCheckbox.name,
          true,
          updatedViewMetrics,
          groupParams
        );
      }
    });

    setViewMetrics(updatedViewMetrics);
  }

  const updateVisibleCategoryGroups = (categoryIndex: number, visibleGroups: number) => {
    if (visibleCategories[categoryIndex] !== visibleGroups) {
      const nextVisibleCategories = visibleCategories.map((categoryCount, index) => {
        return (index === categoryIndex ? visibleGroups : categoryCount);
      });
      setVisibleCategories(nextVisibleCategories);
    }
  }

  const saveView = async () => {
    const isEditing = selector === 'editViewButton';
    const viewMetricsGroups = Object.keys(viewMetrics);
    const currentViewName = viewNameElement.current?.value?.trim() || '';
    setViewNameError(currentViewName.length === 0);

    if (currentViewName.length > 0 && viewMetricsGroups?.length > 0) {
      // we have all necessary data set correctly so we can save
      const tempPayload: DashboardDetails = {
        dashboardReference: isEditing && dashboardSpecsLoadedSuccessfully && dashboardReference.length > 0 ? dashboardReference : undefined,
        title: {
          text: currentViewName,
          isTranslationKeyId: false,
        },
        widgets: [
          {
            widgetReference: isEditing && dashboardSpecsLoadedSuccessfully && dashboardSpecs.widgets[0].widgetReference.length > 0 ? dashboardSpecs.widgets[0].widgetReference : undefined,
            type: 'equipmentUsage',
            view: 'table',
            properties: {
              // filters to be added when they'll be available
              filters: [],
              columns: viewMetrics,
            },
          },
        ],
      };

      try {
        const result = isEditing ? await updateDashboard(tempPayload).unwrap() :  await createDashboard(tempPayload).unwrap()
        pushNotification({
          id: generateSecureNumber(),
          header: t('Lyckad'),
          subheader: t('ÄndringarnaHarSparats'),
          variant: 'success',
        });

        viewModalElement.current?.closeModal();
        clearViewData();
        updateSelectedView(result.dashboardReference);

        if(isEditing) refetchEquipments()
          
      } catch (err) {
        pushNotification({
          id: generateSecureNumber(),
          header: t('Failed'),
          subheader: t('EttFelHarUppstått'),
          variant: 'error',
        });
      }
    }
  };

  const deleteView = async () => {
    const isEditing = selector === 'editViewButton';

    try {
      if(dashboardReference && isEditing) {
        await deleteDashboard(dashboardReference).unwrap()
        pushNotification({
          id: generateSecureNumber(),
          header: t('Lyckad'),
          subheader: t('VP_VynHarRaderats'),
          variant: 'success',
        });

          viewModalElement.current?.closeModal();
          clearViewData();
          updateSelectedView('');
          setConfirmationSelector('');
      } 
    } catch (err) {
      pushNotification({
        id: generateSecureNumber(),
        header: t('Failed'),
        subheader: t('EttFelHarUppstått'),
        variant: 'error',
      });
    }
  }

  const currentViewModal = viewModalElement.current;
  if (selector.length && currentViewModal) {
    currentViewModal.showModal();
  }

  return (
    <>
    <TdsModal
      selector={(selector?.length > 0) ? `#${selector}` : ''}
      size={size}
      prevent={true}
      actions-position='sticky'
      ref={viewModalElement}>
      <span slot='header'>{t(header)}</span>
      <span slot='body'>
        { selector === 'editViewButton' && (isDashboardSpecsLoading || isDashboardSpecsFetching) &&
          <div className='spinnerContainer'>
            <TdsSpinner />
          </div>
        }
        {((dashboardReference.length > 0 && isErrorLoadingDashboardSpecs) ||
          (selector === 'editViewButton' && dashboardReference.length === 0)) &&
          <TdsMessage
            variant='error'
            header={t('EttFelHarUppstått_FörsökIgenSenare_')}
          />
        }
        <div className={(
          selector === 'createViewButton' ||
          (selector === 'editViewButton' &&
          dashboardSpecsLoadedSuccessfully &&
          !isDashboardSpecsFetching &&
          !isDashboardSpecsLoading)) ? '' : 'hiddenElement'}>
          <div className={styles.initialRowContainer}>
            <div className={styles.viewNameContainer}>
              <TdsTextField
                type='text'
                size='sm'
                ref={viewNameElement}
                label={t('VP_ViewName')}
                label-position='outside'
                placeholder={selector === 'createViewButton' ? t('VP_NewView') : t('VP_ViewName')}
                state={viewNameError ? 'error' : 'default'}
                helper={t('_Obligatorisk')}
                value={viewNameValue}
              />
            </div>
            <div className={styles.searchContainer}>
              <TdsTextField 
                type='text'
                ref={searchInputRef}
                size='sm'
                state='default'
                placeholder={t('Sök')}
                helper=''
                value={searchedText}
              > 
                <TdsIcon slot='suffix' name='search' size='20px' />
              </TdsTextField>
            </div>
          </div>
          <div>
            <h6>{t('VP_SelectMetrics')}</h6>
          </div>
          <div>
            { visibleCategories.every(categoryCount => categoryCount === 0) && searchedText &&
              <p>{t('SökningenGavIngaResultat')}</p> }
              <TdsAccordion modeVariant='primary' hideLastBorder>
                {groupParams.map((category, categoryIndex) => {
                  const categoryName = stripNonAlphaCharacters(
                    t(getNameTranslationKey(category.categoryName))
                  );
                  const categoryNameContainsSearchedText = categoryName?.toLowerCase()?.includes(searchedText?.toLowerCase());
                  const noSearchedTextFirstCategory = categoryIndex === 0 && !searchedText?.length && selector === 'createViewButton';
                  const categoryContainsSearchedText = searchedText?.length > 0 && (visibleCategories[categoryIndex] > 0 || categoryNameContainsSearchedText);
                  const checkedParamGroups = getGroupsCountPerCategory(
                    Object.keys(category.groups),
                    viewMetrics.map(group => group.columnName)
                  );

                  return <TdsAccordionItem
                    key={`category_${categoryIndex}`}
                    id={`category_item_${categoryIndex}`}
                    paddingReset
                    expanded={categoryContainsSearchedText ||
                              isAccordionItemExpanded(`category_item_${categoryIndex}`) ||
                              (!paramsSelected &&
                                (categoryContainsSearchedText ||
                                 noSearchedTextFirstCategory ||
                                 (selector === 'editViewButton' && checkedParamGroups > 0))
                              ) || undefined
                            }
                    className={searchedText && visibleCategories[categoryIndex] === 0 && !categoryNameContainsSearchedText ? 'hiddenElement' : undefined}
                    header={`${categoryName} (${checkedParamGroups}/${Object.keys(category.groups).length})`}>
                      <div className={styles.selectButtonsContainer}>
                        <p className={styles.selectLinkContainer}>
                          <TdsLink
                            underline={false}
                            disabled={checkedParamGroups === Object.keys(category.groups).length && allGroupsFullySelected(viewMetrics, category.groups)}
                            onClick={() => markAllCategoryGroups(categoryIndex, true)}>
                              <span>{t('MarkeraAlla')}</span>
                          </TdsLink>
                        </p>
                        <p>
                          <TdsLink
                            underline={false}
                            disabled={checkedParamGroups === 0}
                            onClick={() => markAllCategoryGroups(categoryIndex, false)}>
                              <span>{t('AvmarkeraAlla')}</span>
                          </TdsLink>
                        </p>
                      </div>
                      <ParameterGroupsContainer
                        categoryIndex={categoryIndex}
                        categoryNameContainsSearchedText={categoryNameContainsSearchedText}
                        groups={category.groups}
                        propulsionConsumptionUnit={propulsionConsumptionUnit}
                        gasUnit={gasConsumptionUnit}
                        viewMetrics={viewMetrics}
                        updateParamsList={updateViewParams}
                        searchedText={searchedText}
                        updateVisibleCategoryGroups={updateVisibleCategoryGroups}
                        setCheckboxMarked={setParamsSelected}
                      />
                  </TdsAccordionItem>
                })}
              </TdsAccordion>
          </div>
        </div>
      </span>
      <span slot='actions' className={styles.actionButtons}>
          {
            selector === 'editViewButton' && (
              <TdsButton
                id='deleteViewModal'
                size='sm'
                text={t("TaBort")}
                variant='secondary'
                type='button'
                onClick={() => setConfirmationSelector('deleteViewModal')}
                 />
            )
          }
          <div className={styles.rightSideButtons}>
            <TdsButton
              data-dismiss-modal
              size='sm'
              text={t('Avbryt')}
              type='button'
              variant='secondary'
            />
            {isSavingDashboard || isUpdatingDashboard ? (
              <div className={styles.buttonSpinner}>
                <TdsSpinner size='sm' />
              </div>
            ) : (
              <TdsButton
                size='sm'
                text={selector === 'editViewButton' ? t('Spara') : t('VP_CreateView')}
                type='button'
                disabled={
                  !(Object.keys(viewMetrics)?.length > 0) ||
                  viewNameError ||
                  (!viewNameError && viewNameValue.length === 0)
                }
                onClick={saveView}
              />
            )}
          </div>
      </span>
    </TdsModal>
      <ConfirmationModal
        size='sm'
        selector={confirmationSelector}
        header={t('VP_RaderaVy')}
        message={t('VP_ÄrDuSäker')}
        onCancel={() => setConfirmationSelector('')}
        onConfirm={deleteView}
        confirmButtonText={t('TaBort')}
        danger
        />
    </>
  );
};

export default ViewModal;
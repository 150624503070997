import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TdsBodyCell,
  TdsHeaderCell,
  TdsMessage,
  TdsSpinner,
  TdsTable,
  TdsTableBody,
  TdsTableBodyRow,
  TdsTableHeader,
  TdsTableToolbar,
} from '@scania/tegel-react';
import { TdsTableToolbarCustomEvent } from '@scania/tegel';

import { useGetFuelReportQuery } from '../../state/fuelReport/query';
import { adjustOdometerToProfileSetting, getAvailableIdentifier } from '../../utils/report/tableUtils';
import '../../styles/GeneralStyles.css';

interface FuelReportTableProps {
  selectedGroup?: string | undefined;
  vehicleIdentifier: 'registrationNumber' | 'chassisNumber' | 'alias';
  propulsionConsumptionUnit: string;
  startDate: string;
  endDate: string;
  language: string;
}

const localDate = (eventTime: Date) => (
  new Intl.DateTimeFormat('default', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }).format(new Date(eventTime))
);

const filterGroup = (item: FuelReportItem, group: string = 'groupFilterAll') => {
  return group === 'groupFilterAll' || item.equipment.externalEquipmentGroupReference.includes(group);
}

const FuelReportTable = ({ selectedGroup, vehicleIdentifier, propulsionConsumptionUnit, startDate, endDate, language }: FuelReportTableProps) => {
  const { t } = useTranslation();

  const filterTableRef = useRef<HTMLTdsTableElement>(null);
  const {
    data: serverData,
    isLoading: isFuelEventsLoading,
    isSuccess: fuelEventsLoadedSuccessfully,
    isError: isErrorLoadingFuelEvents,
    isFetching: isFuelEventsFetching,
  } = useGetFuelReportQuery({startTime: startDate, endTime: endDate, language: language});
  const [tableFilter, setTableFilter] = useState<string>('');
  const [filteredData, setFilteredData] = useState<FuelReportItem[]>([]);

  useEffect(() => {
    const freeTextFilter = (item: FuelReportItem) => {
      const {alias, registrationNumber, chassisNumber} = item.equipment;

      const searchableFields: (string | number | Date)[] = [
        alias || registrationNumber || chassisNumber,
        localDate(item.eventTime),
        adjustOdometerToProfileSetting(item.odometerInMeters, propulsionConsumptionUnit),
        `${item.oldLevel} % -> ${item.newLevel} %`,
        `${item.increaseInPercentage} %`,
        item.refuelType
      ];
      if (item.driver?.name) {
        searchableFields.push(item.driver.name)
      };
      return searchableFields.some(field => field.toString().toLocaleLowerCase().includes(tableFilter.toLocaleLowerCase()));
    }

    if (serverData) {
      const newFilteredData = serverData
        .filter((r: FuelReportItem) => filterGroup(r, selectedGroup))
        .filter(freeTextFilter)
      setFilteredData(newFilteredData)
    }
  }, [serverData, selectedGroup, tableFilter, vehicleIdentifier, propulsionConsumptionUnit])

  return (
    <>
      {(isFuelEventsFetching || isFuelEventsLoading) && <div className='spinnerContainer'><TdsSpinner size='md' /></div>}
      { isErrorLoadingFuelEvents && (
        <TdsMessage
          variant='error'
          header={t('EttFelHarUppstått_FörsökIgenSenare_')}
        />
      )}
      {!isFuelEventsFetching && !isFuelEventsLoading && fuelEventsLoadedSuccessfully && filteredData &&
        <TdsTable tableId='filter-table' noMinWidth responsive ref={filterTableRef}>
          <TdsTableToolbar onTdsFilter={(event: TdsTableToolbarCustomEvent<{query: string}>) => setTableFilter(event.detail.query)} tableTitle={t('Filter')} filter />
          <TdsTableHeader>
            <TdsHeaderCell cellKey='equipment' cellValue={t('Utrustning')}/>
            <TdsHeaderCell cellKey='time' cellValue={t('Tid_Tankning_')}/>
            <TdsHeaderCell cellKey='odometer' cellValue={t('Vägmätare')}/>
            <TdsHeaderCell cellKey='event' cellValue={t('Händelse')}/>
            <TdsHeaderCell cellKey='change' cellValue={t('Förändringar')}/>
            <TdsHeaderCell cellKey='increase' cellValue={t('Ökning')}/>
            <TdsHeaderCell cellKey='driver' cellValue={t('Förare')}/>
            <TdsHeaderCell cellKey='address' cellValue={t('Adress')}/>
          </TdsTableHeader>
          <TdsTableBody>
            {!filteredData.length && 
            <TdsTableBodyRow>
              <td className='tds-u-p2' colSpan={8}>{t('IngenDataAttVisa')}</td>
            </TdsTableBodyRow>}
            {filteredData.map((row, index) => {
              const { alias, chassisNumber, registrationNumber } = row.equipment;
              const equipment = getAvailableIdentifier(vehicleIdentifier, { alias, chassisNumber, registrationNumber })

              return (
                <TdsTableBodyRow key={index}>
                  <TdsBodyCell cellKey='equipment'>{equipment}</TdsBodyCell>
                  <TdsBodyCell cellKey='time'>{row.eventTime && localDate(row.eventTime)}</TdsBodyCell>
                  <TdsBodyCell cellKey='odometer'>{adjustOdometerToProfileSetting(row.odometerInMeters, propulsionConsumptionUnit) } { propulsionConsumptionUnit.includes('miles') ? t('Miles'): t('Km') }</TdsBodyCell>
                  <TdsBodyCell cellKey='event'>{row.refuelType}</TdsBodyCell>
                  <TdsBodyCell cellKey='change'>{row.oldLevel} % -&gt; {row.newLevel} %</TdsBodyCell>
                  <TdsBodyCell cellKey='increase'>{row.increaseInPercentage} %</TdsBodyCell>
                  <TdsBodyCell cellKey='driver'>{row.driver?.name || t('IngetFörar_Id')}</TdsBodyCell>
                  <TdsBodyCell cellKey='address'>{row.address}</TdsBodyCell>
                </TdsTableBodyRow>
              )
            })}
          </TdsTableBody>
        </TdsTable>
      }
    </>
    );
};

export default FuelReportTable;
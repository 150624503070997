import { useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';

import { loadUser } from '../state/authentication/utils';
import { useAppDispatch } from '../state/store';

const AuthenticationResolverKeycloak = ({ children }: any) => {
  const [loadUserActionHasBeenDispatched, setLoadUserActionHasBeenDispatched] =
    useState(false);
  const { initialized, keycloak } = useKeycloak();
  const kcInit = !!initialized;
  const kcToken = keycloak?.token ? keycloak.token : '';

  const dispatch = useAppDispatch();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (kcInit && !loadUserActionHasBeenDispatched) {
      dispatch(loadUser(kcToken));
      setLoadUserActionHasBeenDispatched(true);
    }
  });

  return children;
};

export default AuthenticationResolverKeycloak;

import { useCallback, useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { TdsButton, TdsDatetime } from '@scania/tegel-react';

import style from '../styles/DateTimePicker.module.css';
import { useGetStaffInfoQuery } from '../state/user/query';

type DateTimePickerProps = {
  periodStart: string | null;
  periodEnd: string | null;
  handleChange: Function;
  intervalType: string;
  searchNewInterval: Function;
};

const dateFormat = 'YYYY-MM-DD';
const dateTimeFormat = 'YYYY-MM-DDTHH:mm';

const DateTimePicker = ({
  periodStart,
  periodEnd,
  handleChange,
  intervalType,
  searchNewInterval,
}: DateTimePickerProps) => {
  const { t } = useTranslation();
  const startDateInputRef = useRef<HTMLTdsDatetimeElement>(null);
  const endDateInputRef = useRef<HTMLTdsDatetimeElement>(null);

  const now = dayjs().toDate();
  const twoYearsAgo = dayjs()
    .subtract(2, 'years')
    .startOf('year')
    .format(dateTimeFormat);
  const bothDatesValid =
    dayjs(periodStart).isValid() && dayjs(periodEnd).isValid();
  const defaultStartDate = dayjs()
    .subtract(30, 'day')
    .startOf('hour')
    .format(dateTimeFormat);
  const defaultEndDate = dayjs().startOf('hour').format(dateTimeFormat);

  const [startDate, setStartDate] = useState(
    bothDatesValid
      ? dayjs(periodStart).format(dateTimeFormat)
      : defaultStartDate
  );
  const [endDate, setEndDate] = useState(
    bothDatesValid ? dayjs(periodEnd).format(dateTimeFormat) : defaultEndDate
  );
  const [startDateError, setStartDateError] = useState<any>();
  const [endDateError, setEndDateError] = useState<any>();

  const { data: staffData } = useGetStaffInfoQuery(null);

  const dateRangeSchema = Yup.object().shape({
    startDate: Yup.date()
      .transform((value, originalValue) => {
        return originalValue === '' ? null : value;
      })
      .required(t('EttStartdatumKrävs'))
      .min(
        twoYearsAgo,
        t('AngeEttVärdeMellan_0_Och_1_')
          .replace('{1}', dayjs(twoYearsAgo).format(dateFormat))
          .replace('{2}', t('Idag').toLowerCase())
      )
      .max(
        dayjs(now).subtract(1, 'minute').format(dateTimeFormat),
        t('DatumetKanInteInfallaIFramtiden')
      ),
    endDate: Yup.date()
      .transform((value, originalValue) => {
        return originalValue === '' ? null : value;
      })
      .required(t('EttSlutdatumKrävs'))
      .min(
        twoYearsAgo,
        t('AngeEttVärdeMellan_0_Och_1_')
          .replace('{1}', dayjs(twoYearsAgo).format(dateFormat))
          .replace('{2}', t('Idag').toLowerCase())
      )
      .max(
        dayjs(now).format(dateTimeFormat),
        t('DatumetKanInteInfallaIFramtiden')
      )
      .test(
        'end-after-start',
        t('SlutdatumMåsteVaraSenareÄnStartdatum'),
        function (value) {
          return dayjs(value).isAfter(dayjs(this.parent.startDate));
        }
      ),
  });

  const validateInput = useCallback(
    (input: 'startDate' | 'endDate') => {
      try {
        dateRangeSchema.validateSyncAt(input, {
          endDate,
          startDate,
        });

        input === 'startDate'
          ? setStartDateError(undefined)
          : setEndDateError(undefined);
      } catch (err: any) {
        const error = err.errors[0];
        err.path === 'startDate'
          ? setStartDateError(error)
          : setEndDateError(error);
      }
    },
    [dateRangeSchema, endDate, startDate]
  );

  useEffect(() => {
    if (staffData) {
      const today = dayjs();

      let intervalUpdatedStartDate = '';
      let intervalUpdatedEndDate = '';

      switch (intervalType) {
        case 'custom':
          intervalUpdatedStartDate = startDate;
          intervalUpdatedEndDate = endDate;
          break;
        case 'previousWeek':
          const dayOfWeek = dayjs(today).day();

          intervalUpdatedStartDate =
            staffData.startOfWeek === 'sunday'
              ? dayjs(today)
                  .subtract(7 + dayOfWeek, 'days')
                  .set('hour', 0)
                  .set('minutes', 0)
                  .format(dateTimeFormat)
              : dayjs(today)
                  .subtract(6 + dayOfWeek, 'days')
                  .set('hour', 0)
                  .set('minutes', 0)
                  .format(dateTimeFormat);
          intervalUpdatedEndDate = dayjs(intervalUpdatedStartDate)
            .add(6, 'days')
            .set('hour', 23)
            .set('minutes', 59)
            .format(dateTimeFormat);
          break;
      }

      setStartDate(intervalUpdatedStartDate);
      setEndDate(intervalUpdatedEndDate);
    }
  }, [intervalType, periodStart, periodEnd, startDate, staffData, endDate]);

  useEffect(() => {
    if (startDate && endDate) {
      validateInput('startDate');
      validateInput('endDate');
    }
  }, [validateInput, startDate, endDate]);

  const handleSearchNewInterval = () => {
    validateInput('startDate');
    validateInput('endDate');

    if (!startDateError && !endDateError) {
      searchNewInterval({ startDate, endDate });
    }
  };

  useEffect(() => {
    setStartDate(
      bothDatesValid
        ? dayjs(periodStart).format(dateTimeFormat)
        : defaultStartDate
    );
    setEndDate(
      bothDatesValid ? dayjs(periodEnd).format(dateTimeFormat) : defaultEndDate
    );
  }, [
    periodStart,
    periodEnd,
    bothDatesValid,
    defaultStartDate,
    defaultEndDate,
  ]);

  const handleStartDateChange = useCallback(
    (e: any) => {
      setStartDate(e.detail.target.value);
      handleChange();
    },
    [handleChange]
  );

  const handleEndDateChange = useCallback(
    (e: any) => {
      setEndDate(e.detail.target.value);
      handleChange();
    },
    [handleChange]
  );

  useEffect(() => {
    const startDateInput = startDateInputRef.current;
    const endDateInput = endDateInputRef.current;

    return () => {
      startDateInput?.removeEventListener('tdsChange', handleStartDateChange);
      endDateInput?.removeEventListener('tdsChange', handleEndDateChange);
    };
  }, [handleEndDateChange, handleStartDateChange]);

  useEffect(() => {
    const startDateInput = startDateInputRef.current;

    startDateInput?.addEventListener('tdsChange', handleStartDateChange);
  }, [handleStartDateChange]);

  useEffect(() => {
    const endDateInput = endDateInputRef.current;

    endDateInput?.addEventListener('tdsChange', handleEndDateChange);
  }, [handleEndDateChange]);

  return (
    <div className={style.datePickerContainer}>
      <div className={style.inputContainer}>
        <TdsDatetime
          ref={startDateInputRef}
          name='startDate'
          modeVariant='secondary'
          size='md'
          state={startDateError ? 'error' : 'success'}
          helper={startDateError ? startDateError : ''}
          value={startDate}
          min={twoYearsAgo}
          max={dayjs(now).subtract(1, 'minute').format(dateTimeFormat)}
        />
        <span className={style.inputLabel}>{t('Startdatum')}</span>
      </div>

      <div className={style.inputContainer}>
        <TdsDatetime
          ref={endDateInputRef}
          name='endDate'
          modeVariant='secondary'
          size='md'
          state={endDateError ? 'error' : 'success'}
          helper={endDateError ? endDateError : ''}
          value={endDate}
          min={startDate}
          max={dayjs(now).format(dateTimeFormat)}
        />
        <span className={style.inputLabel}>{t('Slutdatum')}</span>
      </div>

      <TdsButton
        variant='primary'
        text={t('Sök')}
        size='sm'
        disabled={startDateError || endDateError}
        onClick={handleSearchNewInterval}
      />
    </div>
  );
};

export default DateTimePicker;

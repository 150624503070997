// @ts-nocheck
import { Fragment, useEffect, useState } from 'react';
import { TdsMessage } from '@scania/tegel-react';
import { useTranslation } from 'react-i18next';

import DataTableHeader from './DataTableHeader';
import DriverDetailsTable from './DriverDetailsTable';
import {
  getAvailableIdentifier,
  displayFormattedValue,
  getCurrentlySelectedDashboard,
  getMatchingPropForVehicleName,
  sortRows,
  createDeepCopy,
} from '../../utils/report/tableUtils';
import chevronUpIcon from '../../assets/chevronUp.svg'
import chevronDownIcon from '../../assets/chevronDown.svg'

import styles from '../../styles/DataTable.module.css';
import "../../styles/GeneralStyles.css"

type dataTableProps = {
  staffDetails: StaffDetails;
  tableData: DataTableStructure;
  userViews: View[];
  groupFilter?: string; // will be used if we ever show a summary row as displayed value in first column for equipment name
  periodStart: string;
  periodEnd: string;
  externalEquipmentRef: string | undefined | null;
  updateSortedData: Function;
};

const getDashboardGroupParameters = (dataRow: any[] = []) => {
  if (dataRow.length === 0) return [];

  const metrics = [];
  dataRow.forEach(column => {
    column.subColumns?.forEach(subcolumn => metrics.push(subcolumn?.name))
  });

  return metrics;
}

const DataTable = ({
  staffDetails,
  tableData,
  userViews,
  groupFilter,
  periodStart,
  periodEnd,
  externalEquipmentRef,
  updateSortedData,
}: dataTableProps) => {
  const { t } = useTranslation();
  const [tableDataContent, setTableDataContent] =
    useState<DataTableStructure>(tableData);
  const [expandedVehicleRows, setExpandedVehicleRows] = useState(externalEquipmentRef ? [externalEquipmentRef] : []);
  const [sortDetails, setSortDetails] = useState({ sortField: '', sortOrder: ''});

  const updateExpandedVehicleRows = (clickedVehicleReference: string) => {
    let tempExpandedRows = [...expandedVehicleRows];
    if (!tempExpandedRows.includes(clickedVehicleReference)) {
      tempExpandedRows.push(clickedVehicleReference);
    }
    else {
      // the row was expanded so we need to remove it from the expanded rows list upon click
      tempExpandedRows = tempExpandedRows.filter((vehicleRowRef) => vehicleRowRef !== clickedVehicleReference);
    }

    setExpandedVehicleRows(tempExpandedRows);
  }
  
  const sortTableRows = (sortField: string, sortOrder: string) => {
    if (sortField) {
      setSortDetails({ sortField: sortField, sortOrder: sortOrder });
      
      const sortedEquipments = sortRows(
        sortField,
        sortOrder,
        tableData.widgets[0].properties.equipments
      );

      const tableDataDeepCopy = createDeepCopy(tableDataContent);
      tableDataDeepCopy.widgets[0].properties.equipments = sortedEquipments;

      setTableDataContent(tableDataDeepCopy);

      updateSortedData(tableDataDeepCopy);
    }
  };

  useEffect(() => setTableDataContent(tableData), [tableData]);

  const selectedDashboard = getCurrentlySelectedDashboard(userViews);
  const dashboardGroups = tableDataContent?.widgets[0].properties.equipments
    ?.length
    ? tableData.widgets[0].properties.equipments[0]?.columns?.map(group => group.columnName)
    : [];
  const dashboardMetrics = getDashboardGroupParameters(tableData?.widgets[0]?.properties?.equipments[0]?.columns);

  return (
    <div>
      {selectedDashboard &&
        !tableDataContent.widgets[0].properties.equipments?.length && (
          <div className="messagePadding"><TdsMessage variant='information' minimal header={t('IngenDataAttVisa')}/></div>
        )}
      {selectedDashboard &&
        tableDataContent.widgets[0].properties.equipments?.length > 0 && (
          <table className='tds-table--responsive fullWidth'>
            <DataTableHeader
              vehicleIdentifier={getMatchingPropForVehicleName(
                staffDetails.vehicleIdentifier
              )}
              propulsionConsumptionUnit={staffDetails.propulsionConsumptionUnit}
              firstRowTableDataContent={
                tableData.widgets[0].properties.equipments[0]
              }
              sortTableContent={sortTableRows}
            />
            <tbody>
              {/* <tr className={`${styles.tableRow}`}>
              <td
                key='keySummary1_1'
                className={`leftAlignedText ${styles.summaryRowCell}`}>
                {t(getGroupKey(groupFilter))}
              </td>
              {dashboardGroups.map(
                (columnName: string, index: number) =>
                  selectedDashboard?.parameters?.hasOwnProperty(columnName) &&
                  tableDataContent.summary[columnName].map(
                    (singleGroupParam: object, groupParamIndex: number) => (
                      <td
                        key={`keySummary${index + 2}_${groupParamIndex + 1}`}
                        className={`rightAlignedText ${styles.summaryRowCell}`}>
                        {displayFormattedValue(singleGroupParam)}
                      </td>
                    )
                  )
              )}
            </tr> */}
              {tableDataContent.widgets[0].properties.equipments?.map(
                (singleVehicle: Equipment, rowIndex: number) => {
                  const isExpandedRow = expandedVehicleRows.includes(singleVehicle.identifier?.externalEquipmentReference)

                  return (
                    <Fragment key={`vehicleRow${rowIndex + 1}Container`}>
                      <tr
                        key={`vehicleRow${rowIndex + 1}`}
                        onClick={(e) => { e.preventDefault(); updateExpandedVehicleRows(singleVehicle.identifier?.externalEquipmentReference)}}
                        // the clickable class should be added only when token permissions allow user to see driver details
                        className={`clickableElement ${styles.tableRow} ${
                          isExpandedRow ? styles.expandedRow :
                          rowIndex % 2 === 0 ? styles.evenRow : styles.oddRow
                        } `}>
                        <td
                          key={`keyVehicleRow${rowIndex + 1}Group1Col1`}
                          className={`leftAlignedText ${styles.stickyLeft} ${
                            isExpandedRow ? styles.expandedRow : rowIndex % 2 === 0 ? styles.evenRow : styles.oddRow
                          }`}>
                            <div style={{display: 'flex', gap: '16px'}} key={`keyVehicleRowImage${rowIndex + 1}Group1Col1`}>
                              <img
                              id={rowIndex === 0 ? 'shepherd-driver-details-button' : ''}
                                src={isExpandedRow ? chevronUpIcon : chevronDownIcon}
                                alt={isExpandedRow ? t('Stäng') : t('Öppen')}
                                width={14}
                              />       
                          {getAvailableIdentifier(
                            getMatchingPropForVehicleName(
                              staffDetails.vehicleIdentifier
                            ),
                            singleVehicle.identifier
                          )}
                          </div>
                        </td>
                        {dashboardGroups.map(
                          (columnName: string, index: number) => {
                            const currentGroup = singleVehicle.columns?.find((group: OverviewEquipmentGroup) => group.columnName === columnName);

                            return (
                              typeof currentGroup !== 'undefined' &&
                              currentGroup.subColumns.map(
                                (
                                  singleMetric: Metric,
                                  metricIndex: number
                                ) => {
                                  const formattedValue = displayFormattedValue(singleMetric);
                                  
                                  return <td
                                    key={`keyVehicleRow${rowIndex + 1}Group${index + 2
                                      }Col${metricIndex + 1}`}
                                    className={`rightAlignedText ${styles.cellWidth}`}>
                                    {!isNaN(formattedValue) ? formattedValue.toLocaleString() : formattedValue}
                                  </td>
                                }
                              )
                            )
                          }
                        )}
                      </tr>
                      { // the table below should also be displayed only when token permissions allow user to see driver details
                        isExpandedRow &&
                              <DriverDetailsTable
                                staffDetails={staffDetails}
                                periodStart={periodStart}
                                periodEnd={periodEnd}
                                sortDetails={sortDetails}
                                equipmentReference={singleVehicle.identifier?.externalEquipmentReference}
                                columns={dashboardMetrics}
                                vehicleRowIndex={rowIndex}
                                vehicleData={singleVehicle}
                              />
                      }
                    </Fragment>
                  )
                }
              )}
            </tbody>
          </table>
        )}
    </div>
  );
};

export default DataTable;
import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { TdsDropdown, TdsDropdownOption } from '@scania/tegel-react';
import { TdsDropdownCustomEvent } from '@scania/tegel';

type GroupDropdownProps = {
  groups: Group[];
  selectedGroup?: string;
  groupsLoadedSuccessfully: boolean;
  isDisabled: boolean;
  onSelection: Function;
};

const GroupsDropdown = ({
  groups,
  selectedGroup,
  groupsLoadedSuccessfully,
  onSelection,
  isDisabled,
}: GroupDropdownProps) => {
  const { t } = useTranslation();
  const groupsDropdownRef = useRef<HTMLTdsDropdownElement>(null);

  const handleGroupSelection = useCallback(
    (e: TdsDropdownCustomEvent<{ name: string; value: string }>) => {
      onSelection(e.detail.value);
    },
    [onSelection]
  );

  useEffect(() => {
    const groupsDropdown = groupsDropdownRef.current;
    if (!groupsDropdown) return;

    groupsDropdown.addEventListener('tdsChange', handleGroupSelection);

    return () => {
      groupsDropdown.removeEventListener('tdsChange', handleGroupSelection);
    };
  }, [handleGroupSelection]);

  return (
    <TdsDropdown
      id='shepherd-groups-dropdown'
      ref={groupsDropdownRef}
      name='groupsDropdown'
      placeholder={t('Välj')}
      modeVariant='secondary'
      disabled={!groupsLoadedSuccessfully || isDisabled}
      size='md'
      defaultValue='groupFilterAll'>
      <TdsDropdownOption key='groupFilterAll' value='groupFilterAll'>
        {t('AllUtrustning')}
      </TdsDropdownOption>
      <TdsDropdownOption
        key='groupFilterNoAffiliation'
        value='groupFilterNoAffiliation'>
        {t('IngenGrupptillhörighet')}
      </TdsDropdownOption>
      {groups.map((groupFilter, index) => (
        <TdsDropdownOption
          key={`groupFilter${index}`}
          value={groupFilter.externalEquipmentGroupReference}>
          {groupFilter.name}
        </TdsDropdownOption>
      ))}
    </TdsDropdown>
  );
};

export default GroupsDropdown;

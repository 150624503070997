import ExcelJS from 'exceljs';
import FileSaver from 'file-saver';
import dayjs from 'dayjs';

import {
  displayFormattedValue,
  generateSubheaderLabel,
  getAvailableIdentifier,
  getMatchingPropForVehicleName,
  stripNonAlphaCharacters,
} from './tableUtils';
import i18n from '../localization';
import {
  headerTitleToTranslationKey,
  vehicleIdentifierToTranslationKey,
} from './convertTranslationKeys';

type Props = {
  sheetTitle: string;
  tableData: ExcelExportDetails;
  staffDetails: StaffDetails;
  interval: {
    startDate: string;
    endDate: string;
  };
};

export const createExcelWorkbook = async ({
  sheetTitle,
  tableData,
  staffDetails,
  interval,
}: Props) => {
  const dateTimeFormat = 'YYYY-MM-DD HH:mm';
  const dashboardGroups = tableData.widgets[0].properties.equipments[0].columns.map((group: OverviewEquipmentGroup) => group.columnName) || [];
  const workbook = new ExcelJS.Workbook();
  let date = new Date();
  workbook.creator = 'Scania - Vehicle Performance';
  workbook.created = date;
  workbook.views = [
    {
      x: 0,
      y: 0,
      width: 10000,
      height: 20000,
      firstSheet: 0,
      activeTab: 0,
      visibility: 'visible',
    },
  ];

  const reportSheet = workbook.addWorksheet(sheetTitle, {
    properties: { defaultColWidth: 20 },
  });

  const customerNameRow = reportSheet.getRow(1);
  customerNameRow.height = 60;
  customerNameRow.alignment = { vertical: 'middle' };
  customerNameRow.getCell(1).value = staffDetails.customerName;

  const overviewPeriodRow = reportSheet.getRow(2);
  overviewPeriodRow.height = 60;
  overviewPeriodRow.alignment = { vertical: 'middle' };
  overviewPeriodRow.getCell(1).value = `${i18n.t(
    'HEADER_ReportType2'
  )}: ${dayjs(interval.startDate).format(dateTimeFormat)} - ${dayjs(
    interval.endDate
  ).format(dateTimeFormat)}`;

  const headerRow = reportSheet.getRow(4);

  const createTableHeaderRow = () => {
    const tableHeaderRows: any[] = [];

    const translatedEquipmentKey = { param: i18n.t('Utrustning'), count: 1 };
    const dashboardParams = dashboardGroups.map(
      (columnName) => {
        const currentGroup = tableData.widgets[0].properties.equipments[0].columns.find((group: OverviewEquipmentGroup) => group.columnName === columnName);
        return typeof currentGroup !== "undefined" && {
          param: stripNonAlphaCharacters(
            i18n.t(headerTitleToTranslationKey(columnName))
          ),
          count: currentGroup.subColumns.length,
        }
      }
    );

    tableHeaderRows.push(translatedEquipmentKey, ...dashboardParams);
    return tableHeaderRows;
  };

  const createTableUnitsRow = () => {
    const tableUnitsRow: string[] = [];

    const translatedVehicleIdentifier = i18n.t(
      vehicleIdentifierToTranslationKey(
        getMatchingPropForVehicleName(staffDetails.vehicleIdentifier)
      )
    );
    const dashboardUnits = dashboardGroups.map(
      (columnName: string) => {
        const currentGroup = tableData.widgets[0].properties.equipments[0].columns.find((group: OverviewEquipmentGroup) => group.columnName === columnName);

        return typeof currentGroup !== "undefined" ? currentGroup.subColumns.map(
          (metric: Metric) =>
            generateSubheaderLabel(metric)
        ) : []
      }
    );

    tableUnitsRow.push(translatedVehicleIdentifier, ...dashboardUnits.flat(3));
    return tableUnitsRow;
  };

  const createTableBodyRows = () => {
    const tableBodyRows: string[][] = [];

    tableData.widgets[0]?.properties?.equipments?.forEach((singleVehicle: WidgetEquipment) => {
      const currentVehicle = getAvailableIdentifier(
        getMatchingPropForVehicleName(staffDetails.vehicleIdentifier),
        singleVehicle.identifier
      );

      const currentVehicleParamsValues = dashboardGroups?.map(
        (columnName: string) => {
          const metricGroup = singleVehicle.columns.find((group: OverviewEquipmentGroup) => group.columnName === columnName)

          return typeof metricGroup !== "undefined" ? metricGroup.subColumns.map(
            (metric: Metric) =>
              displayFormattedValue(metric)
          ) : []
        }
          
      );

      tableBodyRows.push([
        currentVehicle,
        ...currentVehicleParamsValues.flat(3),
      ]);
    });

    return tableBodyRows;
  };

  const generateTableHeader = () => {
    const tableHeaderCells = createTableHeaderRow();
    let currentColumnNumber = 1;

    for (let i = 0; i < tableHeaderCells.length; i++) {
      if (tableHeaderCells[i].count === 1) {
        let cell = reportSheet.getCell(headerRow.number, currentColumnNumber);
        cell.value = tableHeaderCells[i].param;
        currentColumnNumber++;
      } else {
        reportSheet.mergeCells(
          headerRow.number,
          currentColumnNumber,
          headerRow.number,
          currentColumnNumber + tableHeaderCells[i].count - 1
        );
        reportSheet.getCell(headerRow.number, currentColumnNumber).value =
          tableHeaderCells[i].param;
        currentColumnNumber += tableHeaderCells[i].count;
      }
    }

    headerRow.alignment = { vertical: 'middle' };
    headerRow.font = { bold: true, underline: 'single' };
  };

  // -----Table generation-----

  generateTableHeader();

  const tableUnitsRow = createTableUnitsRow();
  const unitsRow = reportSheet.addRow(tableUnitsRow);
  unitsRow.alignment = { horizontal: 'right' };
  unitsRow.getCell(1).alignment = { horizontal: 'left' };

  const tableBodyRows = createTableBodyRows();
  const bodyRows = reportSheet.addRows(tableBodyRows);
  bodyRows.forEach((row) => {
    row.alignment = { horizontal: 'right' };
    row.getCell(1).alignment = { horizontal: 'left' };
  });

  // -----Table download-----

  try {
    const buffer = await workbook.xlsx.writeBuffer();
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8;';
    let EXCEL_EXTENSION = '.xlsx';
    const blob = new Blob([buffer], { type: fileType });

    FileSaver.saveAs(
      blob,
      `${i18n.t('Översikt')} ${dayjs(interval.startDate).format(
        dateTimeFormat
      )} - ${dayjs(interval.endDate).format(dateTimeFormat)}` + EXCEL_EXTENSION
    );
  } catch (e) {
    console.error(e);
  }
};

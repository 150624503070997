import 'i18next';
import i18next from 'i18next';
import HttpApi, { HttpBackendOptions } from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

i18next
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init<HttpBackendOptions>(
    {
      returnNull: false,
      backend: {
        loadPath: '/localization/{{lng}}.json',
      },
      load: 'currentOnly',
      fallbackLng: ['en-GB'],
    },
    (err) => {
      if (err) return console.log(err);
      return;
    }
  );

export default i18next;

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const parseJSON = (response: any) => response.json();

export const checkStatus = (response: any) => {
  if (response.status > 200) {
    try {
      return parseJSON(response)
        .catch(() => response)
        .then(() => {
          const error = new Error(response.statusText);
          return Promise.reject(error);
        });
    } catch (e) {
      const error = new Error(response.statusText);
      return Promise.reject(error);
    }
  }
  return Promise.resolve(response);
};

export const uuidv4 = () => crypto.randomUUID();

export const formatDateToUTC = (date: string, utcDiff: string = '') => {
  // assuming we'll receive the UTC difference as '-02:45' or '+05:00', the + sign is not mandatory
  let utcMinutes = null;
  let utcDiffSign = 1;
  if (utcDiff !== null && utcDiff !== undefined && utcDiff.length > 1) {
    const splitHoursMin = utcDiff.split(':');
    const hours = Number(splitHoursMin[0]);
    const minutes = Number(splitHoursMin[1]);

    utcMinutes = Math.abs(hours) * 60 + minutes;
    utcDiffSign = Math.sign(hours) < 0 ? -1 : 1;
  } else {
    if (utcDiff === 'Z') {
      utcMinutes = 0;
    }
  }

  return utcMinutes !== null ?
                dayjs(date).subtract(utcMinutes * utcDiffSign, 'minute').format() : 
                dayjs(date).utc().format();
};

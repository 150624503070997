import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import dayjs from 'dayjs';

import { useGetCustomerGroupsQuery } from '../state/groups/query';
import { useGetStaffInfoQuery } from '../state/user/query';

import FuelReportTable from '../components/fuelReport/FuelReportTable';
import GroupsDropdown from '../components/dropdowns/GroupsDropdown';

import '../styles/GeneralStyles.css';
import '../styles/FuelReport.css';

import { getMatchingPropForVehicleName } from '../utils/report/tableUtils';
import { TdsMessage, TdsSpinner } from '@scania/tegel-react';
import DateTimePicker from '../components/DateTimePicker';
import IntervalPicker from '../components/IntervalPicker';

const dateTimeFormat = 'YYYY-MM-DDTHH:mm';

const FuelReport = () => {
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const loggedIn = !!(keycloak?.authenticated && keycloak.token);
  const defaultStartDate = dayjs()
    .subtract(30, 'day')
    .startOf('hour')
    .format(dateTimeFormat);
  const defaultEndDate = dayjs().startOf('hour').format(dateTimeFormat);
  const [selectedGroup, setSelectedGroup] = useState(undefined);
  const [fuelReportStartDate, setFuelReportStartDate] = useState(defaultStartDate)
  const [fuelReportEndDate, setFuelReportEndDate] = useState(defaultEndDate)
  const [intervalType, setIntervalType] = useState<'custom' | 'previousWeek'>('custom')

  const {
    data: staffData,
    isLoading: isStaffLoading,
    isSuccess: isStaffLoadingSuccess,
    isError: isStaffLoadingError,
    refetch: refetchStaffData,
  } = useGetStaffInfoQuery(null);

  const {
    data: customerGroups,
    isSuccess: groupsLoadedSuccessfully,
    isError: isErrorLoadingGroups,
    isFetching: isCustomerGroupsFetching,
    refetch: refetchUserGroups,
  } = useGetCustomerGroupsQuery(isStaffLoadingSuccess ? null : skipToken);  

  useEffect(() => {
    if (isStaffLoadingError) {
      refetchStaffData();
    }
  }, [isStaffLoadingError, refetchStaffData]);
  
  useEffect(() => {
    if (isErrorLoadingGroups) {
      refetchUserGroups();
    }
  }, [isErrorLoadingGroups, refetchUserGroups]);

  const searchNewInterval = ({startDate, endDate}: {
    [key: string]: string;
  }) => {
    setFuelReportStartDate(startDate)
    setFuelReportEndDate(endDate)
  }

  return (
      <div className='mainContainer'>
        <div className='pageHeader'>
          <div className='centerAlignedFlex'>
            <h5>{t('Bränslerapport')}</h5>

          { loggedIn && staffData && groupsLoadedSuccessfully && (
            <>
              <p className='headerItemDivider'></p>
              <div className='groupsDropdownContainer'>
                <GroupsDropdown
                  groups={customerGroups}
                  selectedGroup={selectedGroup}
                  groupsLoadedSuccessfully={groupsLoadedSuccessfully}
                  onSelection={setSelectedGroup}
                  isDisabled={false}
                />
              </div>
            </>
          )}
        </div>
          {
            loggedIn && staffData && (
              <div className='overviewDatePickerContainer'>
                <IntervalPicker
                  intervalType={intervalType}
                  changeIntervalType={(interval: 'custom' | 'previousWeek') => {
                  setIntervalType(interval)
                }} />
                <DateTimePicker
                  periodStart={fuelReportStartDate}
                  periodEnd={fuelReportEndDate}
                  handleChange={
                    () => {
                      if (intervalType !== 'custom') {
                        setIntervalType('custom')
                      }
                    }}
                  intervalType={intervalType}
                  searchNewInterval={searchNewInterval}
                />
                </div>
            )
          }
      </div>
      { loggedIn && (
        <div className='pageContentContainer'>
          {(isStaffLoading || isCustomerGroupsFetching) && (
            <div className='spinnerContainer'>
              <TdsSpinner size='md' />
            </div>
          )}
          { staffData && (
            <>
              { isErrorLoadingGroups && (
                <TdsMessage
                  variant='error'
                  header={t('EttFelHarUppstått_FörsökIgenSenare_')}
                />
              )}
              { groupsLoadedSuccessfully &&
                <div className='onlyTableContainer'>
                  <FuelReportTable 
                    selectedGroup={selectedGroup}
                    vehicleIdentifier={getMatchingPropForVehicleName(staffData.vehicleIdentifier)}
                    propulsionConsumptionUnit={staffData.propulsionConsumptionUnit}
                    startDate={fuelReportStartDate}
                    endDate={fuelReportEndDate}
                    language={staffData.language}
                  />
                </div>
              }
            </>
          )}
        </div>
      )}
    </div>
  );
};


export default FuelReport;
import { useRef, useState, useCallback, useEffect, useMemo, RefObject, createRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  TdsButton,
  TdsDropdown,
  TdsDropdownOption,
  TdsModal,
  TdsTextField,
  TdsTextarea
} from '@scania/tegel-react';
 
import styles from '../../styles/EmissionSpecsModal.module.css';
import { emissionSpecsSchema, getDisconnectedEquipments } from '../../utils/emissions';
 
const DISPLAY_NAME_MAX_LENGTH = 60;
const DESCRIPTION_MAX_LENGTH = 200;
 
type EmissionSpecsModalProps = {
  header: string;
  size: ModalSize;
  show: boolean;
  selector: string;
  emissionSpec?: EmissionSpecItem | null;
  onSubmit: Function;
  onCancel: Function;
  activeVehicles: any[];
  vehicleIdentifier: string;
};
 
type EmissionSpecFormFieldNames = 'displayName' | 'description' | 'nox' | 'cO2' | 'hc' | 'co' | 'pm' | 'connectedEquipments'
 
type NumberFieldObject = {
  name: EmissionSpecFormFieldNames
  label: string
}
 
const numberFormFields: NumberFieldObject[] = [
  {name: 'nox', label: 'NOx'},
  {name: 'cO2', label: 'CO2'},
  {name: 'hc', label: 'HC'},
  {name: 'co', label: 'CO'},
  {name: 'pm', label: 'PM'}
];
 
const emissionSpecFormFields: EmissionSpecFormFieldNames[] = ['displayName', 'description','nox','cO2','hc','co','pm', 'connectedEquipments'];
 
const getInitialValues = (emissionSpec: EmissionSpecItem | null | undefined): Record<keyof EmissionSpecItem, string | number> =>
  emissionSpecFormFields.reduce<Record<keyof EmissionSpecItem, string | number>>((acc, field) => {
    const isTextField = ['displayName', 'description', 'connectedEquipments'].includes(field);
    const connectedEquipmentValue = emissionSpec && emissionSpec.connectedEquipments ? emissionSpec.connectedEquipments.join(',') : '';
    
    acc[field] = emissionSpec ? (field === 'connectedEquipments' ? connectedEquipmentValue : emissionSpec[field]) : isTextField ? '' : 0;
    return acc;
  }, {} as Record<keyof EmissionSpecItem, string | number>);
 
const getInitialErrors = (): Record<keyof EmissionSpecItem, string> =>
  emissionSpecFormFields.reduce<Record<keyof EmissionSpecItem, string>>((acc, field) => {
    acc[field] = '';
    return acc;
  }, {} as Record<keyof EmissionSpecItem, string>);
 
const EmissionSpecsModal = ({
  header,
  size,
  selector,
  show,
  emissionSpec,
  onSubmit,
  onCancel,
  activeVehicles,
  vehicleIdentifier
}: EmissionSpecsModalProps) => {
  const { t } = useTranslation();
  const specModalRef = useRef<HTMLTdsModalElement>(null);
  const [formValues, setFormValues] = useState(selector === 'createSpecButton' ? getInitialValues(null) : getInitialValues(emissionSpec));
  const [formErrors, setFormErrors] = useState(getInitialErrors());
 
  const refsById = useMemo(() => {
    const refs: Record<string, RefObject<HTMLTdsTextFieldElement | HTMLTdsTextareaElement | HTMLTdsDropdownElement>> = {};
    emissionSpecFormFields.forEach((item) => {
      refs[item] = createRef()
    })
    return refs
  }, [])
 
  const setValueForField = (field: EmissionSpecFormFieldNames, value: any) => {
    setFormValues(prevState => ({
      ...prevState,
      [field]: value
    }))
  }
 
  const setErrorForField = (error: string, field?: EmissionSpecFormFieldNames) => {
    if(field) {
      setFormErrors(prevState => ({
        ...prevState,
        [field]: error
      }))
    }
  }
 
  const validateField = useCallback(async (field: EmissionSpecFormFieldNames, value: any) => {
    try {
      await emissionSpecsSchema.validateAt(field, { [field]: value });
      setErrorForField('', field)
    } catch (error: any) {
      setErrorForField(error.message, field)
    }
  }, [])
 
  const handleChange = useCallback((event: any) => {
    const field = event.target.name as EmissionSpecFormFieldNames;
    const value = event.target.value;
  
    if (field === 'connectedEquipments') return;
 
    if (['displayName', 'description'].includes(field)) {
      setValueForField(field, value || '');
    } else {
      const valueIsFloat = value.split('').includes('.') || value.split('').includes(',')
      if(value.startsWith(0) && value.length >= 2 && !valueIsFloat) {
        const firstPositiveDigitIndex = value.split('').findIndex((digit: string) => Number(digit) > 0)
        const emissionValue = Number(value.slice(firstPositiveDigitIndex))
        setValueForField(field, emissionValue)
      } else {
        setValueForField(field, value)
      }
    }
 
    validateField(field, value)
  }, [validateField])
 
  const clearAndClose = useCallback(() => {
    specModalRef?.current?.closeModal();
    setFormValues(getInitialValues(null));
    onCancel();
  }, [onCancel])
 
  const handleSubmit = async () => {
    try {
      await emissionSpecsSchema.validate(formValues, {abortEarly: false});
      setFormErrors(getInitialErrors());

      const isFormValid = await emissionSpecsSchema.isValid(formValues);

      if(isFormValid) {
        const updatedConnectedEquipments = formValues.connectedEquipments?.toString().length > 0 ?
            formValues.connectedEquipments.toString().replace(' ', '').split(',') : [];
        const updatedDisconnectedEquipments = getDisconnectedEquipments(emissionSpec?.connectedEquipments, updatedConnectedEquipments);
        const newOrUpdatedSpec: CreateArgs_EmissionSpecItem = {
          ...emissionSpec,
          displayName: formValues.displayName as string,
          description: formValues.description as string,
          nox: Number(formValues.nox),
          cO2: Number(formValues.cO2),
          hc: Number(formValues.hc),
          co: Number(formValues.co),
          pm: Number(formValues.pm),
          cO2Urea: 0,
          connectedEquipments: updatedConnectedEquipments,
          disconnectedEquipments: updatedDisconnectedEquipments
        }
     
        onSubmit(newOrUpdatedSpec)
        clearAndClose();
      }
    } catch (error: any) {
      const validationErrors: any = {}
      error.inner.forEach((err: any) => {
        validationErrors[err.path as EmissionSpecFormFieldNames] = err.message
      })
      setFormErrors(validationErrors)
    }
  }

  const handleEquipmentsSelection = useCallback(
    (e: any) => {
      let optionsCopy = [...formValues.connectedEquipments.toString().replace(' ', '').split(',')];
      if (optionsCopy[0]?.length === 0) { optionsCopy = []; }

      if (e.detail.selected && !optionsCopy.includes(e.detail.value)) {
        optionsCopy.push(e.detail.value);
      } else {
        optionsCopy = optionsCopy.filter((option) => option !== e.detail.value);
      }
      setValueForField('connectedEquipments', optionsCopy.join(','));
    }, [formValues.connectedEquipments]
  );
 
  useEffect(() => {
    emissionSpecFormFields.forEach((key) => refsById[key].current?.addEventListener('tdsChange', handleChange as EventListener));  
    return () => {
      emissionSpecFormFields.forEach((key) => refsById[key].current?.removeEventListener('tdsChange', handleChange as EventListener));  
    };
  }, [handleChange, refsById]);

  useEffect(() => {
    const connectedEquipmentsDropdown = refsById['connectedEquipments'].current;
    if (!connectedEquipmentsDropdown) return;

    connectedEquipmentsDropdown.addEventListener('tdsSelect', handleEquipmentsSelection);

    return () => {
      connectedEquipmentsDropdown.removeEventListener(
        'tdsSelect',
        handleEquipmentsSelection
      );
    };
  }, [handleEquipmentsSelection, refsById]);
 
  useEffect(() => {
    const specModal = specModalRef.current;
    specModal?.addEventListener('tdsClose', clearAndClose);
    return () => specModal?.removeEventListener('tdsClose', clearAndClose);
  }, [clearAndClose]);
 
  const currentSpecModal = specModalRef.current;
  if (selector.length && currentSpecModal) {
    currentSpecModal.showModal();
  }
 
  return (
    <TdsModal
      header={t(header)}
      selector={(selector?.length > 0) ? `#${selector}` : ''}
      show={show}
      size={size}
      prevent={true}
      actions-position='sticky'
      ref={specModalRef}>
      <span slot='body'>
        <div className={styles.modalBodyContainer} >
          <div>
            <TdsTextField
              type='text'
              size='sm'
              ref={refsById['displayName'] as RefObject<HTMLTdsTextFieldElement>}
              label={t('Specification name')}
              label-position='outside'
              mode-variant='primary'
              placeholder={t('AngeEttUniktNamnFörUtsläppsspecifikationen')}
              state={formErrors.displayName ? 'error' : 'default'}
              maxLength={DISPLAY_NAME_MAX_LENGTH}
              helper={t('_Obligatorisk')}
              value={formValues.displayName as string}
              name='displayName'
              disabled={emissionSpec?.isScaniaSpecification}
            />
            <TdsTextarea
              cols={100}
              rows={3}
              ref={refsById['description'] as RefObject<HTMLTdsTextareaElement>}
              label={t('Beskrivning')}
              placeholder={t('AngeEnBeskrivningFörUtsläppsspecifikationen')}
              label-position='outside'
              mode-variant='primary'
              value={formValues.description as string}
              maxLength={DESCRIPTION_MAX_LENGTH}
              name='description'
              state={formErrors.description ? 'error' : 'default'}
              disabled={emissionSpec?.isScaniaSpecification}
            />
            <div className={styles.numberFieldsContainer}>
              {numberFormFields.map(field =>
              <div style={{display: 'flex', flexDirection: 'column', minHeight: '140px'}} key={field.name}>
                <div className={styles.numberField}>
                  <TdsTextField
                    size='sm'
                    ref={refsById[field.name] as RefObject<HTMLTdsTextFieldElement>}
                    label={t(field.label)}
                    label-position='outside'
                    mode-variant='primary'
                    state={formErrors[field.name] ? 'error' : 'default'}
                    value={formValues[field.name].toString()}
                    name={field.name}
                    type='number'
                    helper={t('_Obligatorisk')}
                    disabled={emissionSpec?.isScaniaSpecification}
                    />
                </div>
                  <span className={styles.numericFielError}>{formErrors[field.name]}</span>
                </div>
              )}
            </div>
            <div>
              { activeVehicles && activeVehicles?.length > 0 &&
                <TdsDropdown 
                  ref={refsById['connectedEquipments'] as RefObject<HTMLTdsDropdownElement>}
                  name='connectedEquipments'
                  multiselect
                  filter
                  modeVariant='secondary'
                  size='md'
                  placeholder={t('VäljUtrustning')}
                  label={t('KopplaUtsläppsspecifikation')}
                  label-position='outside'
                  noResultText={t('SökningenGavIngaResultat')}
                  openDirection='up'
                  default-value={formValues.connectedEquipments}>
                    { activeVehicles.map((singleVehicle, vehicleIndex) =>
                      <TdsDropdownOption
                        key={`vehicle${vehicleIndex}`}
                        value={singleVehicle.externalEquipmentReference}>
                          {singleVehicle[vehicleIdentifier] || singleVehicle.chassisNumber}
                      </TdsDropdownOption>
                    )}
                </TdsDropdown>
              }
            </div>
          </div>
        </div>
      </span>
      <span slot='actions'>
        <div className={styles.actionButtons}>
            <TdsButton
              size='sm'
              text={t('Avbryt')}
              type='button'
              variant='ghost'
              onClick={clearAndClose}
              />
            <TdsButton
              size='sm'
              text={selector === 'createSpecButton' ? t('Skapa') : t('Spara')}
              type='button'
              onClick={handleSubmit}
            />
        </div>
      </span>
    </TdsModal>
  );
};
 
export default EmissionSpecsModal;
import { useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { useLocation, useNavigate } from 'react-router-dom';

const AuthRedirect = ({ children }: Children) => {
  const { initialized, keycloak } = useKeycloak();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (keycloak && initialized && !keycloak.token) {
      keycloak.login({
        redirectUri: `${process.env.REACT_APP_HOST}/overview`,
      });
    } else if (location.pathname === '/') {
      navigate('/overview');
    } else {
      navigate(`${location.pathname}${location.search}`);
    }
  }, [initialized, keycloak, location.pathname, location.search, navigate]);
  return children;
};

export default AuthRedirect;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  resolved: false,
  authenticated: false,
  expiredSession: false,
  user: {
    extStaffRef: undefined,
    extCustRef: undefined,
    permissions: {
      user: [],
      functional: undefined,
    },
  },
};

const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    requestLogout(state) {
      state.loading = true;
    },
    requestLogoutSuccess(state) {
      state.resolved = true;
      state.authenticated = false;
    },
    foundExistingAuthentication(state, action) {
      state.loading = false;
      state.authenticated = true;
      state.resolved = true;
      state.user = action.payload;
    },
    foundNoExistingAuthentication(state) {
      state.resolved = true;
      state.loading = false;
      state.authenticated = false;
    },
    expiredExistingAuthentication(state) {
      state.resolved = true;
      state.authenticated = false;
      state.expiredSession = true;
    },
  },
});

export const {
  requestLogout,
  requestLogoutSuccess,
  foundExistingAuthentication,
  foundNoExistingAuthentication,
  expiredExistingAuthentication,
} = authenticationSlice.actions;

export default authenticationSlice.reducer;

import decode from 'jwt-decode';

import authentication from '../../api/authentication';
import keycloak from '../../keycloak/keycloak';
import {
  expiredExistingAuthentication,
  requestLogout,
  requestLogoutSuccess,
  foundExistingAuthentication,
  foundNoExistingAuthentication,
} from './slice';
import { AppDispatch } from '../store';

function mapTokenToUser(token: any) {
  return {
    extStaffRef: token.ExternalStaffReference
      ? token.ExternalStaffReference
      : token.externalStaffReference,
    extCustRef: token.ExternalCustomerReference
      ? token.ExternalCustomerReference
      : token.externalCustomerReference,
    permissions: {
      user: token.UserPermissions,
      functional: token.FunctionalPermissions,
    },
  };
}

function isExpiredSession(error: any) {
  return error.status === 404 || error.status === 401;
}

const loadFmpToken = async (kcToken: string) => {
  const tokenPromise = authentication.getFmpToken(kcToken);

  const result = await tokenPromise;
  if (result) {
    return {
      ...result,
      user: mapTokenToUser(decode(result.access_token)),
    };
  }
};

export const expiredExistingAuth = () => (dispatch: AppDispatch) => {
  dispatch(expiredExistingAuthentication());
};

export const logout = () => (dispatch: AppDispatch) => {
  dispatch(requestLogout());
  authentication.logout();
  dispatch(requestLogoutSuccess());
};

export const decideCredentialsToUse = (ssoCreds: any) => {
  if (ssoCreds && (keycloak || {}).authenticated) {
    authentication.storeTokens(ssoCreds);
    return foundExistingAuthentication(ssoCreds.user);
  } else {
    return foundNoExistingAuthentication();
  }
};

export const loadUser = (kcToken: string) => async (dispatch: AppDispatch) => {
  let ssoUsePromise = new Promise(function (resolve, reject) {
    resolve(null);
  });

  if (kcToken) {
    ssoUsePromise = loadFmpToken(kcToken);
  }

  try {
    const result = await ssoUsePromise;
    dispatch(decideCredentialsToUse(result));
  } catch (e) {
    authentication.logout();
    if (isExpiredSession(e)) {
      return dispatch(expiredExistingAuthentication());
    }
    return dispatch(foundNoExistingAuthentication());
  }
};

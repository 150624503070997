import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useKeycloak } from '@react-keycloak/web';
import { TdsSpinner } from '@scania/tegel-react';

import { useGetStaffInfoQuery } from '../state/user/query';

import EmissionSpecsTable from '../components/settings/EmissionSpecsTable';

import '../styles/GeneralStyles.css';
import { getMatchingPropForVehicleName } from '../utils/report/tableUtils';

const Settings = () => {
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const loggedIn = !!(keycloak?.authenticated && keycloak.token);

  const {
    data: staffData,
    isLoading: isStaffLoading,
    isError: isStaffLoadingError,
    refetch: refetchStaffData,
  } = useGetStaffInfoQuery(null);

  useEffect(() => {
    if (isStaffLoadingError) {
      refetchStaffData();
    }
  }, [isStaffLoadingError, refetchStaffData]);

  return (
    <div style={{ position: 'relative', top: 0, left: 0 }}>
      <div className='mainContainer'>
        <div className='pageHeader'>
          <div className='centerAlignedFlex'>
            <h5>{t('VP_EmissionSpecifications')}</h5>
        </div>
      </div>
      { loggedIn && (
        <div className='pageContentContainer'>
          {isStaffLoading && (
            <div className='spinnerContainer'>
              <TdsSpinner size='md' />
            </div>
          )}
          { staffData &&
            <EmissionSpecsTable vehicleIdentifier={getMatchingPropForVehicleName(staffData.vehicleIdentifier)} />
          }
        </div>
      )}
    </div>
    </div>
  );
};


export default Settings;
import { useCallback, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';
import { Helmet } from 'react-helmet';

import { useAppSelector } from '../../state/store';
import { getExtStaffRef, isUserAuthenticated } from '../../state/authentication/selectors';
import { useGetStaffInfoQuery } from '../../state/user/query';
import ThemeLoader from './ThemeLoader';
import i18n from '../../utils/localization';
import Header from './Header';
import SideMenu from './SideMenu';
import Banner from './Banner';
import { useIsFeatureEnabled } from '../../utils/hooks';

const Layout = () => {  
  const { initialized, keycloak } = useKeycloak();
  const isZendeskWidgetEnabled = useIsFeatureEnabled('VPZendeskWidget');
  const userIsAuthenticated = useAppSelector(isUserAuthenticated);
  const extStaffRef = useAppSelector(getExtStaffRef);
  const [widgetFgToken, setWidgetFgToken] = useState(keycloak.token);

  const handleWidgetFgToken = useCallback((e: any) => {
    e.preventDefault();
    setWidgetFgToken(e.detail);
  }, []);

  useEffect(() => {
    window.addEventListener('kcTokenSuccessfullyFetched', handleWidgetFgToken);

    return () => {
      window.removeEventListener('kcTokenSuccessfullyFetched', handleWidgetFgToken);
    }
  }, [handleWidgetFgToken]);

  useEffect(() => {
    const hideMopinionButton = (event: any) => {
      event.preventDefault();
      const surveyContent = document.getElementById('surveyContent');
      if (surveyContent && isZendeskWidgetEnabled) {
        surveyContent.style.display = 'none';
      }
    }

    document.addEventListener('mopinion_loaded', hideMopinionButton);

    return () => {
      document.removeEventListener('mopinion_loaded', hideMopinionButton);
    };
  }, [isZendeskWidgetEnabled]);

  const {
    data: staffData,
    refetch: refetchStaff,
    isSuccess: isStaffLoadingSuccess,
  } = useGetStaffInfoQuery(null);

  useEffect(() => {
    refetchStaff();
  }, [extStaffRef, refetchStaff]);

  useEffect(() => {
    if (staffData) {
      localStorage.setItem('i18nextLng', staffData.language);
      i18n.changeLanguage(staffData.language);
    }
  }, [staffData]);

  const staffLanguage = isStaffLoadingSuccess ? staffData.language : 'en-GB';

  return (
    <>
      <ThemeLoader />
      <Header language={staffLanguage} />
      <Banner />
      <SideMenu language={staffLanguage} />
      {staffData && (
        <Helmet>
          <title>{i18n.t("Fordonsuppföljning")}</title>
          <meta
            name="description"
            content={i18n.t("VP_FöljUpp")}
          />
        </Helmet>
      )}
      <Outlet />
      {staffData && (
        <scp-cookie-settings
          culturecode={staffLanguage}
          privacyurl={`${process.env.REACT_APP_MY_SCANIA_LINK}/#/privacy`}>
          <scp-cookie-footer hide-cookie-button culturecode={staffLanguage}></scp-cookie-footer>
        </scp-cookie-settings>
      )}
      {isZendeskWidgetEnabled &&
        userIsAuthenticated &&
        (widgetFgToken && widgetFgToken?.length > 0) &&
        initialized &&
          <zendesk-widget id="zendesk-widget-container" auth-token={widgetFgToken} user-language={staffLanguage}></zendesk-widget>
      }
    </>
  );
};

export default Layout;

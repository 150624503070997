/* eslint-disable import/no-anonymous-default-export */
import decode from './jwtDecoder';

export default {
  isValid: (token: string) => {
    if (!token || token === null) {
      return false;
    }
    try {
      if (decode(token).exp < Date.now() / 1000) {
        return false;
      }
    } catch (error) {
      return false;
    }
    return true;
  },
};

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { uuidv4, formatDateToUTC } from '../../utils/api';

const X_CLIENT_ID = process.env.REACT_APP_X_CLIENT || 'vp';

export const driverDetailsApi = createApi({
  reducerPath: 'driverDetailsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_VP_BFF_HOST}/api/v1`,
    prepareHeaders: (headers) => {
      const accessToken = sessionStorage.getItem('access_token');
      headers.set('Authorization', `Bearer ${accessToken}`);
      headers.set('X-Correlation-ID', uuidv4());
      headers.set('X-Client', X_CLIENT_ID);
      headers.set('content-type', 'application/json');
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getDriverDetails:  builder.query({
      query: ({
        queryStart,
        queryStop,
        propulsionConsumptionUnit,
        gasConsumptionUnit,
        externalEquipmentReference,
        utcDiff,
        parameters
      }) => ({
        url: `/widget/equipmentsummary/table/${externalEquipmentReference}/driver`,
        method: 'POST',
        params: {
          queryStart: formatDateToUTC(queryStart, utcDiff),
          queryStop: formatDateToUTC(queryStop, utcDiff),
          propulsionConsumptionUnit,
          gasConsumptionUnit
        },
        body: JSON.stringify({parameters: parameters}),
      }),
    }),
  }),
});

export const { useGetDriverDetailsQuery } = driverDetailsApi;
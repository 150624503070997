import { AuthClientEvent } from './auth-types';
import { checkStatus, parseJSON, uuidv4 } from '../utils/api';
import keycloak from '../keycloak/keycloak';
import tokenValidator from '../keycloak/jwtTokenValidator';

type ResponseTokenParam = {
  access_token: string;
  refresh_token: string;
};

const ACCESS_TOKEN_KEY = 'access_token';
const REFRESH_TOKEN_KEY = 'refresh_token';
const PORTAL_AUTH_KEY = 'portal_auth';
const AUTH_HOST: string = process.env.REACT_APP_AUTH_HOST || '';
const X_CLIENT_ID: string = process.env.REACT_APP_X_CLIENT || '';
const KEYCLOAK_EVENTS = {
  onReady: 'onReady',
  onAuthSuccess: 'onAuthSuccess',
  onAuthError: 'onAuthError',
  onAuthRefreshSuccess: 'onAuthRefreshSuccess',
  onAuthRefreshError: 'onAuthRefreshError',
  onAuthLogout: 'onAuthLogout',
  onTokenExpired: 'onTokenExpired',
};

let updateTokenInterval: ReturnType<typeof setInterval>;

const unauthorizedError = () => new Error('Unauthorized');

const storeTokens = (response: ResponseTokenParam) => {
  const accessToken = response.access_token;
  sessionStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
  sessionStorage.setItem(
    PORTAL_AUTH_KEY,
    JSON.stringify({ token: accessToken })
  );
  if (response.refresh_token) {
    sessionStorage.setItem(REFRESH_TOKEN_KEY, response.refresh_token);
  } else {
    // Remove refresh token if not used
    localStorage.removeItem(REFRESH_TOKEN_KEY);
  }

  return response;
};

const getFmpToken = (kcToken: string) => {
  window.dispatchEvent(new CustomEvent('kcTokenSuccessfullyFetched', { detail: kcToken }));
  const getFMPTokenPromise = fetch(`${AUTH_HOST}/token`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${kcToken}`,
      'X-Correlation-ID': uuidv4(),
      'X-Client': X_CLIENT_ID,
    },
  })
    .then(checkStatus)
    .then(parseJSON)
    .then(storeTokens)
    .catch((err) => console.log(err));

  return getFMPTokenPromise;
};

const scheduleTokenRefresh = () => {
  if (updateTokenInterval) {
    clearInterval(updateTokenInterval);
  }
  updateTokenInterval = setInterval(() => refreshTokens(), 240000); // refresh token every 4 minutes
};

const refreshTokens = () => {
  keycloak
    .updateToken(-1) // -1: to force refresh the token
    .then((refr: any) => {
      if (refr) {
        getFmpToken(keycloak.token || '');
      }
    })
    .catch((err: any) => {
      console.log('Error refreshing keycloak token: ', err);
    });
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  handleKeycloakEvent: (event: AuthClientEvent) => {
    if (event === KEYCLOAK_EVENTS.onAuthSuccess) {
      scheduleTokenRefresh();
    }

    if (
      event === KEYCLOAK_EVENTS.onAuthLogout ||
      event === KEYCLOAK_EVENTS.onAuthError ||
      event === KEYCLOAK_EVENTS.onAuthRefreshError
    ) {
      sessionStorage.clear();
      localStorage.removeItem('x-switch');
      keycloak.logout()
    }

    if (event === KEYCLOAK_EVENTS.onTokenExpired) {
      refreshTokens();
    }
  },
  getFmpToken,
  storeTokens,
  refreshTokens,
  scheduleTokenRefresh,
  logout: () => {
    sessionStorage.clear();
    localStorage.removeItem('x-switch');    
    keycloak.logout({ redirectUri: `${window.location.origin}/login` })
  },
  getValidAccessToken: (forceRefresh = false) => {
    if (!forceRefresh) {
      const accessToken = sessionStorage.getItem(ACCESS_TOKEN_KEY) || '';

      if (tokenValidator.isValid(accessToken)) {
        return Promise.resolve(accessToken);
      }
    }

    const refreshToken = sessionStorage.getItem(REFRESH_TOKEN_KEY) || null;

    if (refreshToken === null) {
      return Promise.reject(unauthorizedError());
    }

    return refreshTokens();
  },
};

import { RootState } from '../store';

export const isLoadingAuthentication = (state: RootState) =>
  state.authentication.loading;

export const isUserAuthenticated = (state: RootState) =>
  state.authentication.authenticated;

export const getExtStaffRef = (state: RootState): string | undefined =>
  state.authentication.user ? state.authentication.user.extStaffRef : undefined;

export const getExternalCustomerReference = (state: RootState) =>
  state.authentication.user ? state.authentication.user.extCustRef : null;

const countryMap = [
  {
    code: 'AF',
    name: 'Afghanistan',
    externalCountryReference: '8a9508af-7e38-4b75-8996-31cd20a6c9a8',
    dialCode: '+93'
  },
  {
    code: 'AX',
    name: 'Aland Islands',
    externalCountryReference: '02ad0109-2be1-415a-8c35-eec3801a543e',
    dialCode: '+358'
  },
  {
    code: 'AL',
    name: 'Albania',
    externalCountryReference: '2f0c6748-c814-48ed-a6b5-ba184b538b1b',
    localizationKey: 'Albanien',
    dialCode: '+355'
  },
  {
    code: 'DZ',
    name: 'Algeria',
    externalCountryReference: '809dbc24-b7df-4736-9725-c714773d2465',
    localizationKey: 'Algeriet',
    dialCode: '+213'
  },
  {
    code: 'AS',
    name: 'American Samoa',
    externalCountryReference: '0193c05a-2a3f-4fc6-b031-015d853b2810',
    dialCode: '+1684'
  },
  {
    code: 'AD',
    name: 'Andorra',
    externalCountryReference: 'da87e876-9a5d-4d10-8b4e-7e1f609b24db',
    dialCode: '+376'
  },
  {
    code: 'AO',
    name: 'Angola',
    externalCountryReference: '51b6799d-b863-4d7c-9e84-fa1217f6768e',
    dialCode: '+244'
  },
  {
    code: 'AI',
    name: 'Anguilla',
    externalCountryReference: '8471e075-46a4-440b-8a61-33c986ab5cd3',
    dialCode: '+1264'
  },
  {
    code: 'AQ',
    name: 'Antarctica',
    externalCountryReference: '9e562df5-f1c3-4e07-8c8d-7973c8e2874f',
    dialCode: '+672'
  },
  {
    code: 'AG',
    name: 'Antigua and Barbuda',
    externalCountryReference: 'e51c9e27-9d48-4685-a3cb-4f70252b617c',
    dialCode: '+1268'
  },
  {
    code: 'AR',
    name: 'Argentina',
    externalCountryReference: '0a1e440e-a212-4e0d-8f7f-d1cc6e0100c5',
    localizationKey: 'Argentina',
    dialCode: '+54'
  },
  {
    code: 'AM',
    name: 'Armenia',
    externalCountryReference: 'f91c50b3-b15f-4800-a0b7-b3bab17bcab4',
    dialCode: '+374'
  },
  {
    code: 'AW',
    name: 'Aruba',
    externalCountryReference: '35ec01b3-6c9c-43d5-8fa3-5ab7002342ac',
    dialCode: '+297'
  },
  {
    code: 'AU',
    name: 'Australia',
    externalCountryReference: 'efa01dcb-1ab6-4df9-a0a5-0c1b6d5b58df',
    localizationKey: 'Australien',
    dialCode: '+61'
  },
  {
    code: 'AT',
    name: 'Austria',
    externalCountryReference: 'c0aac937-6c2b-434c-b7a5-b8facc2d2e14',
    localizationKey: 'Österrike',
    dialCode: '+43'
  },
  {
    code: 'AZ',
    name: 'Azerbaijan',
    externalCountryReference: '62af0309-6bfe-4e2a-ae41-b2bbb54af233',
    dialCode: '+994'
  },
  {
    code: 'BS',
    name: 'Bahamas',
    externalCountryReference: '44748be7-a982-4699-9078-d9c545f9a532',
    dialCode: '+1242'
  },
  {
    code: 'BH',
    name: 'Bahrain',
    externalCountryReference: '12368710-a944-4dd6-a263-c7c7dc35f9b7',
    dialCode: '+973'
  },
  {
    code: 'BD',
    name: 'Bangladesh',
    externalCountryReference: 'd7138d89-a416-45fc-b5f8-71442dc9e3d6',
    dialCode: '+880'
  },
  {
    code: 'BB',
    name: 'Barbados',
    externalCountryReference: 'd0f59b7b-98ec-4e22-ad55-80c6d08c8c11',
    dialCode: '+1246'
  },
  {
    code: 'BY',
    name: 'Belarus',
    externalCountryReference: '3773d86c-1c30-416f-8304-f3bbd0c8a66f',
    localizationKey: 'Vitryssland',
    dialCode: '+375'
  },
  {
    code: 'BE',
    name: 'Belgium',
    externalCountryReference: '28b4b828-ff99-4480-aad4-54300f504f8c',
    localizationKey: 'Belgien',
    dialCode: '+32'
  },
  {
    code: 'BZ',
    name: 'Belize',
    externalCountryReference: 'b3c9ac1e-eba3-4c5b-894c-9dce8a054f52',
    dialCode: '+501'
  },
  {
    code: 'BJ',
    name: 'Benin',
    externalCountryReference: 'e94db580-cabd-4d7a-881e-98b4fadf3d24',
    dialCode: '+229'
  },
  {
    code: 'BM',
    name: 'Bermuda',
    externalCountryReference: '9e853672-23b0-4670-adf7-c4049a66d0bc',
    dialCode: '+1441'
  },
  {
    code: 'BT',
    name: 'Bhutan',
    externalCountryReference: '192c38f7-57e1-4432-8f1f-88aba3707497',
    dialCode: '+975'
  },
  {
    code: 'BO',
    name: 'Bolivia',
    externalCountryReference: '675a0266-7430-44aa-9565-9ee4b71999ff',
    dialCode: '+591'
  },
  {
    code: 'BQ',
    name: 'Bonaire, Sint Eustatius and Saba',
    externalCountryReference: 'ef1810f9-5498-457d-a6eb-0d2bccc79f45',
    dialCode: '+599'
  },
  {
    code: 'BA',
    name: 'Bosnia and Herzegovina',
    externalCountryReference: '6d205cb5-7474-45cc-b5da-e3c0726ef3fc',
    localizationKey: 'BosnienOchHercegovina',
    dialCode: '+387'
  },
  {
    code: 'BW',
    name: 'Botswana',
    externalCountryReference: '43e973ac-a84a-44a9-b573-ed7f9c6b533f',
    dialCode: '+267'
  },
  {
    code: 'BV',
    name: 'Bouvet Island',
    externalCountryReference: '943ccc15-fd2a-407f-b1fb-75afc53ffa3a'
  },
  {
    code: 'BR',
    name: 'Brazil',
    externalCountryReference: '1d61628c-6ec0-486f-a701-df6201b0541c',
    localizationKey: 'Brasilien',
    dialCode: '+55'
  },
  {
    code: 'IO',
    name: 'British Indian Ocean Territory',
    externalCountryReference: '9c90dc98-ed67-4e34-bf4d-250df9bdeda6',
    dialCode: '+246'
  },
  {
    code: 'BN',
    name: 'Brunei Darussalam',
    externalCountryReference: 'e504a96c-db62-4512-a7da-1d67ae34f626',
    dialCode: '+673'
  },
  {
    code: 'BG',
    name: 'Bulgaria',
    externalCountryReference: '1eceb76e-c9c8-4fb6-aa3b-fc2b0262c53f',
    localizationKey: 'Bulgarien',
    dialCode: '+359'
  },
  {
    code: 'BF',
    name: 'Burkina Faso',
    externalCountryReference: '3d309fc7-a1f4-4541-b562-3c4a9ca4b32b',
    dialCode: '+226'
  },
  {
    code: 'BI',
    name: 'Burundi',
    externalCountryReference: 'abcc6af4-b604-4bec-a31a-7a9792a30782',
    dialCode: '+257'
  },
  {
    code: 'KH',
    name: 'Cambodia',
    externalCountryReference: 'e8217961-17e2-4bcf-90e9-d2531dd97448',
    dialCode: '+855'
  },
  {
    code: 'CM',
    name: 'Cameroon',
    externalCountryReference: '6c8b87a1-24f1-480c-ab86-14c485ac68d1',
    dialCode: '+237'
  },
  {
    code: 'CA',
    name: 'Canada',
    externalCountryReference: 'a22abfe1-7b54-446d-9dc3-1ca9ab6845ab',
    dialCode: '+1'
  },
  {
    code: 'CV',
    name: 'Cape Verde',
    externalCountryReference: 'a8560f6a-75e8-434b-8308-a6e9b76d132d',
    dialCode: '+238'
  },
  {
    code: 'KY',
    name: 'Cayman Islands',
    externalCountryReference: 'b8edb898-3e27-4a69-9b23-a2906394a4d4',
    dialCode: '+1345'
  },
  {
    code: 'CF',
    name: 'Central African Republic',
    externalCountryReference: '493c6115-5156-4934-a479-281c6557f2ee',
    dialCode: '+236'
  },
  {
    code: 'TD',
    name: 'Chad',
    externalCountryReference: 'e7ef7f38-3205-4747-acee-17f342588ca3',
    dialCode: '+235'
  },
  {
    code: 'CL',
    name: 'Chile',
    externalCountryReference: 'dc29ad8c-18e3-45be-a6e3-ffbc04d9450f',
    localizationKey: 'Chile',
    dialCode: '+56'
  },
  {
    code: 'CN',
    name: 'China',
    externalCountryReference: '7f2e0eb6-cd3d-47c6-b39f-3403761920fb',
    localizationKey: 'Kina',
    dialCode: '+86'
  },
  {
    code: 'CX',
    name: 'Christmas Island',
    externalCountryReference: 'f95c1ad1-556c-4e8a-8f3a-f1cf753f7cc0',
    dialCode: '+61'
  },
  {
    code: 'CC',
    name: 'Cocos (Keeling) Islands',
    externalCountryReference: '2cc4c3ca-d474-47e9-a9ab-f93c1ef2f65f',
    dialCode: '+61'
  },
  {
    code: 'CO',
    name: 'Colombia',
    externalCountryReference: 'a95b5972-a1b8-401d-80d2-2c3b7378ecf6',
    dialCode: '+57'
  },
  {
    code: 'KM',
    name: 'Comoros',
    externalCountryReference: '27c6f79e-9895-4d6c-b0dc-3c52564a304e',
    dialCode: '+269'
  },
  {
    code: 'CG',
    name: 'Congo',
    externalCountryReference: '2d3915ef-a96c-4f36-bba0-ca61caa4d9da',
    dialCode: '+242'
  },
  {
    code: 'CD',
    name: 'Congo, the Democratic Republic of the',
    externalCountryReference: 'e2effe6d-731f-4049-a849-1e101be48292',
    dialCode: '+243'
  },
  {
    code: 'CK',
    name: 'Cook Islands',
    externalCountryReference: 'f011feae-3f11-4683-9746-8bfc640edc64',
    dialCode: '+682'
  },
  {
    code: 'CR',
    name: 'Costa Rica',
    externalCountryReference: 'b22ca270-59a3-4759-88e9-d6fceb21cb2b',
    dialCode: '+506'
  },
  {
    code: 'CI',
    name: "Côte d'Ivoire",
    externalCountryReference: '39185a21-d25a-4a4f-b552-a5022697d1c1',
    dialCode: '+225'
  },
  {
    code: 'HR',
    name: 'Croatia',
    externalCountryReference: '7951d833-17e7-4332-9b63-cbebeba0dcc9',
    dialCode: '+385'
  },
  {
    code: 'CU',
    name: 'Cuba',
    externalCountryReference: 'a21be4a0-bc5b-4950-98a8-f39ec4be8b47',
    dialCode: '+53'
  },
  {
    code: 'CW',
    name: 'Curaçao',
    externalCountryReference: '4488f89a-ba8f-4437-9cd2-f7de44c2122d',
    dialCode: '+599'
  },
  {
    code: 'CY',
    name: 'Cyprus',
    externalCountryReference: '31f2bd22-f9c4-4609-ac48-82ecc48ec0c2',
    dialCode: '+357'
  },
  {
    code: 'CZ',
    name: 'Czech Republic',
    externalCountryReference: '44c64511-98ef-4325-906f-f810c09fdb88',
    localizationKey: 'Tjeckien',
    dialCode: '+420'
  },
  {
    code: 'DK',
    name: 'Denmark',
    externalCountryReference: 'b4ebe743-bb23-4686-9212-f509782e2169',
    localizationKey: 'Danmark',
    dialCode: '+45'
  },
  {
    code: 'DJ',
    name: 'Djibouti',
    externalCountryReference: '5d6317c1-635f-4736-8dce-21c9265e7ad5',
    dialCode: '+253'
  },
  {
    code: 'DM',
    name: 'Dominica',
    externalCountryReference: 'd5c92f39-f109-4a17-9499-06cd8ab26f3b',
    dialCode: '+1767'
  },
  {
    code: 'DO',
    name: 'Dominican Republic',
    externalCountryReference: '82e5c17d-cef7-42ab-80eb-74d19d059589',
    localizationKey: 'DominikanskaRepubliken',
    dialCode: '+1'
  },
  {
    code: 'EC',
    name: 'Ecuador',
    externalCountryReference: '0d2eae44-ba02-4797-b2a7-a5a4cd4b43d0',
    dialCode: '+593'
  },
  {
    code: 'EG',
    name: 'Egypt',
    externalCountryReference: '21766ddb-d384-414b-a835-801aefcfd79d',
    localizationKey: 'Egypten',
    dialCode: '+20'
  },
  {
    code: 'SV',
    name: 'El Salvador',
    externalCountryReference: 'b550f488-a524-4755-9efd-8439f3f01cb2',
    dialCode: '+503'
  },
  {
    code: 'GQ',
    name: 'Equatorial Guinea',
    externalCountryReference: '807f0b22-475e-4579-b70e-b4d050565407',
    dialCode: '+240'
  },
  {
    code: 'ER',
    name: 'Eritrea',
    externalCountryReference: '6864dd21-1901-4726-af7d-5a638f0d0f07',
    dialCode: '+291'
  },
  {
    code: 'EE',
    name: 'Estonia',
    externalCountryReference: '70158b74-5ae6-4274-886e-624202831537',
    localizationKey: 'Estland',
    dialCode: '+372'
  },
  {
    code: 'ET',
    name: 'Ethiopia',
    externalCountryReference: '34007009-a403-4bfb-aac6-d71bb1dc3330',
    dialCode: '+251'
  },
  {
    code: 'FK',
    name: 'Falkland Islands (Malvinas)',
    externalCountryReference: '12cc614c-4662-4148-8ad8-1b838bba58bd',
    dialCode: '+500'
  },
  {
    code: 'FO',
    name: 'Faroe Islands',
    externalCountryReference: '7041fec0-e574-49e0-aec2-3c5d233c7c34',
    dialCode: '+298'
  },
  {
    code: 'FJ',
    name: 'Fiji',
    externalCountryReference: '816e44cb-7146-4311-a889-57417f0b772d',
    dialCode: '+679'
  },
  {
    code: 'FI',
    name: 'Finland',
    externalCountryReference: '56760235-bb17-4eff-873c-aea22ca9d692',
    localizationKey: 'Finland',
    dialCode: '+358'
  },
  {
    code: 'FR',
    name: 'France',
    externalCountryReference: '12a56adc-b069-4933-b842-c89aa9d6fcc3',
    localizationKey: 'Frankrike',
    dialCode: '+33'
  },
  {
    code: 'GF',
    name: 'French Guiana',
    externalCountryReference: '9ee4929a-a630-4609-ad52-0be16d79428c',
    dialCode: '+594'
  },
  {
    code: 'PF',
    name: 'French Polynesia',
    externalCountryReference: '7b1dfd04-cb95-48c4-a121-a1ce803ee6c1',
    dialCode: '+689'
  },
  {
    code: 'TF',
    name: 'French Southern Territories',
    externalCountryReference: '1c662002-3c87-4110-92a8-da41eeebf01a'
  },
  {
    code: 'GA',
    name: 'Gabon',
    externalCountryReference: '389b5881-c0bc-439e-9ff5-2cffa76bcc72',
    dialCode: '+241'
  },
  {
    code: 'GM',
    name: 'Gambia',
    externalCountryReference: 'e05a3cbf-cecb-4f1e-8a09-181938a64f0e',
    dialCode: '+220'
  },
  {
    code: 'GE',
    name: 'Georgia',
    externalCountryReference: 'ca4b6c2e-1219-49b3-9943-b85bf0dea738',
    localizationKey: 'Georgien',
    dialCode: '+995'
  },
  {
    code: 'DE',
    name: 'Germany',
    externalCountryReference: 'ded718f0-1602-449f-90ae-db02e79388fe',
    localizationKey: 'Tyskland',
    dialCode: '+49'
  },
  {
    code: 'GH',
    name: 'Ghana',
    externalCountryReference: '616f2e45-f786-483f-a351-a0eeae93dd84',
    dialCode: '+233'
  },
  {
    code: 'GI',
    name: 'Gibraltar',
    externalCountryReference: 'ee08255e-88d6-4bf2-ad19-6e549558db5d',
    dialCode: '+350'
  },
  {
    code: 'GR',
    name: 'Greece',
    externalCountryReference: 'be76f9bb-dfe4-4c49-8512-72c2dabad2f4',
    localizationKey: 'Grekland',
    dialCode: '+30'
  },
  {
    code: 'GL',
    name: 'Greenland',
    externalCountryReference: '0e7179f6-3b82-470c-a774-9439a620381d',
    dialCode: '+299'
  },
  {
    code: 'GD',
    name: 'Grenada',
    externalCountryReference: '58a4cd87-be3e-43b7-a214-87c3f2fc97f3',
    dialCode: '+1473'
  },
  {
    code: 'GP',
    name: 'Guadeloupe',
    externalCountryReference: '31ef1f43-c1bc-4c3c-bd84-f6af706e6145',
    dialCode: '+590'
  },
  {
    code: 'GU',
    name: 'Guam',
    externalCountryReference: '078321bf-b3bc-4841-a1de-cf84b186f7db',
    dialCode: '+1671'
  },
  {
    code: 'GT',
    name: 'Guatemala',
    externalCountryReference: 'e4917c8a-9a3e-4693-93f8-688a58436c0d',
    dialCode: '+502'
  },
  {
    code: 'GG',
    name: 'Guernsey',
    externalCountryReference: 'd5e88444-3df2-4cea-a650-bec2a8741f3e',
    dialCode: '+44'
  },
  {
    code: 'GN',
    name: 'Guinea',
    externalCountryReference: '77f423f9-a7b4-4f28-b580-c9dcf1a5b8c6',
    dialCode: '+224'
  },
  {
    code: 'GW',
    name: 'Guinea-Bissau',
    externalCountryReference: '84f96ce6-1669-4de9-8f5c-9ad003902ad9',
    dialCode: '+245'
  },
  {
    code: 'GY',
    name: 'Guyana',
    externalCountryReference: '856e6563-6fa2-4517-9087-48f088f77459',
    dialCode: '+592'
  },
  {
    code: 'HT',
    name: 'Haiti',
    externalCountryReference: '16a7c235-5436-4a02-a294-ca248c981b05',
    dialCode: '+509'
  },
  {
    code: 'HM',
    name: 'Heard Island and McDonald Islands',
    externalCountryReference: '355f30ab-b9b6-40f7-b10b-cd6ebe44e6b5'
  },
  {
    code: 'VA',
    name: 'Holy See (Vatican City State)',
    externalCountryReference: 'a1828d84-6731-4bb2-bcb4-f59810a8ba56',
    dialCode: '+379'
  },
  {
    code: 'HN',
    name: 'Honduras',
    externalCountryReference: '22f96783-2fe7-48b8-abf5-cb0e0a2d7406',
    dialCode: '+504'
  },
  {
    code: 'HK',
    name: 'Hong Kong',
    externalCountryReference: '78dbcdd1-3a52-4c6e-81f5-5114e38878ea',
    localizationKey: 'Hongkong',
    dialCode: '+852'
  },
  {
    code: 'HU',
    name: 'Hungary',
    externalCountryReference: '4668fe6d-462e-4478-856d-85a428f62cf2',
    localizationKey: 'Ungern',
    dialCode: '+36'
  },
  {
    code: 'IS',
    name: 'Iceland',
    externalCountryReference: 'fade5a0c-bdda-4c11-9254-da2eb1e6c516',
    localizationKey: 'Island',
    dialCode: '+354'
  },
  {
    code: 'IN',
    name: 'India',
    externalCountryReference: '806b1b28-e06f-4579-a2b4-303c7e27e990',
    dialCode: '+91'
  },
  {
    code: 'ID',
    name: 'Indonesia',
    externalCountryReference: 'd1ecffbf-e586-4ed5-a38e-8aba73c88673',
    localizationKey: 'Indonesien',
    dialCode: '+62'
  },
  {
    code: 'IR',
    name: 'Iran',
    externalCountryReference: '43c560f1-cba4-4768-97cf-571029f261b7',
    dialCode: '+98'
  },
  {
    code: 'IQ',
    name: 'Iraq',
    externalCountryReference: '508daf57-6a89-4605-8142-e4e0421c8bd8',
    dialCode: '+964'
  },
  {
    code: 'IE',
    name: 'Ireland',
    externalCountryReference: '1932e6f9-1d4f-4b75-a70b-f283b03b1f81',
    localizationKey: 'Irland',
    dialCode: '+353'
  },
  {
    code: 'IM',
    name: 'Isle of Man',
    externalCountryReference: '56baa88f-9044-4bc5-9790-5799c67beebf',
    localizationKey: 'IsleOfMan',
    dialCode: '+44'
  },
  {
    code: 'IL',
    name: 'Israel',
    externalCountryReference: '3f62161f-8a9a-4110-95fc-faafe5f4739f',
    localizationKey: 'Israel',
    dialCode: '+972'
  },
  {
    code: 'IT',
    name: 'Italy',
    externalCountryReference: 'b67b6620-571d-4579-a2dc-6716d7b66fe5',
    localizationKey: 'Italien',
    dialCode: '+39'
  },
  {
    code: 'JM',
    name: 'Jamaica',
    externalCountryReference: 'a3373c90-a1d4-45a9-b738-a6dd4c33762a',
    dialCode: '+1876'
  },
  {
    code: 'JP',
    name: 'Japan',
    externalCountryReference: '3844ddc3-10f8-4c70-8f53-dd40c47001a0',
    dialCode: '+81'
  },
  {
    code: 'JE',
    name: 'Jersey',
    externalCountryReference: 'd1b63971-cb78-4bd3-825d-26993d9ad883',
    dialCode: '+44'
  },
  {
    code: 'JO',
    name: 'Jordan',
    externalCountryReference: 'ff3dd02e-2edf-4cc6-8b02-6a6a3587f442',
    dialCode: '+962'
  },
  {
    code: 'KZ',
    name: 'Kazakhstan',
    externalCountryReference: '0da2f34f-a0b5-499b-abba-d3f4118dfec8',
    localizationKey: 'Kazakstan',
    dialCode: '+7'
  },
  {
    code: 'KE',
    name: 'Kenya',
    externalCountryReference: 'a8f59f7b-e254-49d9-b3ae-9159d69dd46a',
    localizationKey: 'Kenya',
    dialCode: '+254'
  },
  {
    code: 'KI',
    name: 'Kiribati',
    externalCountryReference: 'b9f8eb0e-e07b-4fdb-831f-7cc9247f4b63',
    dialCode: '+686'
  },
  {
    code: 'KR',
    name: 'Korea',
    externalCountryReference: 'ffc65065-02eb-4491-a6a8-fffed5df8a69',
    localizationKey: 'Sydkorea',
    dialCode: '+82'
  },
  {
    code: 'KP',
    name: "Korea, Democratic People's Republic of",
    externalCountryReference: 'e2c9bc34-e48f-4ff3-ba2a-c0ade120f441',
    dialCode: '+850'
  },
  {
    code: 'KW',
    name: 'Kuwait',
    externalCountryReference: '5c921a17-1728-4b83-b600-b2946e85d4e9',
    localizationKey: 'Kuwait',
    dialCode: '+965'
  },
  {
    code: 'KG',
    name: 'Kyrgyzstan',
    externalCountryReference: '8029e4be-fa2e-4b4d-8fbe-91844ce0eca1',
    dialCode: '+996'
  },
  {
    code: 'LA',
    name: 'Laos',
    externalCountryReference: '6837cd8c-3693-4755-8aa7-ca1631ac4e63',
    dialCode: '+856'
  },
  {
    code: 'LV',
    name: 'Latvia',
    externalCountryReference: '260c9c6e-f179-4e57-b68f-fc8d0c60e540',
    localizationKey: 'Lettland',
    dialCode: '+371'
  },
  {
    code: 'LB',
    name: 'Lebanon',
    externalCountryReference: '21034898-71bb-4779-8bbf-e81ba8734e10',
    localizationKey: 'Libanon',
    dialCode: '+961'
  },
  {
    code: 'LS',
    name: 'Lesotho',
    externalCountryReference: '3d5094c8-796b-4ff3-a06f-0737f66746d3',
    dialCode: '+266'
  },
  {
    code: 'LR',
    name: 'Liberia',
    externalCountryReference: 'f1a179fd-c4d8-4859-ae52-12565c2c030a',
    dialCode: '+231'
  },
  {
    code: 'LY',
    name: 'Libya',
    externalCountryReference: 'eb24759e-e149-48d9-843c-bc20c8b1b62d',
    dialCode: '+218'
  },
  {
    code: 'LI',
    name: 'Liechtenstein',
    externalCountryReference: '4fedcf18-16d2-43aa-b7c9-fb1fd87e3b66',
    dialCode: '+423'
  },
  {
    code: 'LT',
    name: 'Lithuania',
    externalCountryReference: 'a047c5b8-1612-4615-9f9a-7f5d48303133',
    localizationKey: 'Litauen',
    dialCode: '+370'
  },
  {
    code: 'LU',
    name: 'Luxembourg',
    externalCountryReference: '80607e34-8250-414c-ac59-a6220002b914',
    localizationKey: 'Luxemburg',
    dialCode: '+352'
  },
  {
    code: 'MO',
    name: 'Macao',
    externalCountryReference: '54ce0080-5af1-4a59-8c8b-03906acd794b',
    dialCode: '+853'
  },
  {
    code: 'MK',
    name: 'Macedonia',
    externalCountryReference: '91eabb2f-6358-4aa9-b730-1894a0199a3c',
    localizationKey: 'Makedonien',
    dialCode: '+389'
  },
  {
    code: 'MG',
    name: 'Madagascar',
    externalCountryReference: '4b937695-17af-4e53-84e6-701d5fae6a01',
    dialCode: '+261'
  },
  {
    code: 'MW',
    name: 'Malawi',
    externalCountryReference: 'b3d60445-9218-4834-9d4f-9a28af09eca0',
    dialCode: '+265'
  },
  {
    code: 'MY',
    name: 'Malaysia',
    externalCountryReference: '7d7f9e61-d7cf-408e-99a7-9313ca361317',
    localizationKey: 'Malaysia',
    dialCode: '+60'
  },
  {
    code: 'MV',
    name: 'Maldives',
    externalCountryReference: '2505ff8e-8e85-4828-bd7b-73f0098cc272',
    dialCode: '+960'
  },
  {
    code: 'ML',
    name: 'Mali',
    externalCountryReference: 'dff7e5a5-19f8-49df-8a57-18b30138eb1e',
    dialCode: '+223'
  },
  {
    code: 'MT',
    name: 'Malta',
    externalCountryReference: 'eb08a676-56b2-4ed6-bed2-9d73ccb2c90b',
    dialCode: '+356'
  },
  {
    code: 'MH',
    name: 'Marshall Islands',
    externalCountryReference: '4a98d6f8-7bc9-4d4f-a4e6-515201cae7fa',
    dialCode: '+692'
  },
  {
    code: 'MQ',
    name: 'Martinique',
    externalCountryReference: '9cde16c3-1aa9-4b36-8d95-17b841c6330e',
    dialCode: '+596'
  },
  {
    code: 'MR',
    name: 'Mauritania',
    externalCountryReference: 'e190ff7c-44b5-4190-821c-f32ce3d069d1',
    dialCode: '+222'
  },
  {
    code: 'MU',
    name: 'Mauritius',
    externalCountryReference: '530aee32-7737-40a5-be42-04ce9b07d1cc',
    dialCode: '+230'
  },
  {
    code: 'YT',
    name: 'Mayotte',
    externalCountryReference: '1b677378-5eed-492a-b855-7be99bb295d6',
    dialCode: '+262'
  },
  {
    code: 'MX',
    name: 'Mexico',
    externalCountryReference: '78ad8b1e-bf05-4077-995c-88b4f58a91d3',
    localizationKey: 'Mexiko',
    dialCode: '+52'
  },
  {
    code: 'FM',
    name: 'Micronesia',
    externalCountryReference: '0e607f08-2b25-44c4-9cfa-355f6a3135a8',
    dialCode: '+691'
  },
  {
    code: 'MD',
    name: 'Moldova',
    externalCountryReference: '5313b254-435b-4dd4-8a6e-6ab4365f849a',
    dialCode: '+373'
  },
  {
    code: 'MC',
    name: 'Monaco',
    externalCountryReference: '73ef8571-f524-4183-bb16-5f20b998df70',
    dialCode: '+377'
  },
  {
    code: 'MN',
    name: 'Mongolia',
    externalCountryReference: 'c3988ba9-374c-4c84-9014-1879be8e8530',
    dialCode: '+976'
  },
  {
    code: 'ME',
    name: 'Montenegro',
    externalCountryReference: '07b8b53c-e88d-48ff-85f6-032d150c45c6',
    localizationKey: 'Montenegro',
    dialCode: '+382'
  },
  {
    code: 'MS',
    name: 'Montserrat',
    externalCountryReference: '18427480-1375-4e21-bd81-6d746978ff97',
    dialCode: '+1664'
  },
  {
    code: 'MA',
    name: 'Morocco',
    externalCountryReference: '892da738-625c-480e-8989-d12e19f9477d',
    localizationKey: 'Marocko',
    dialCode: '+212'
  },
  {
    code: 'MZ',
    name: 'Mozambique',
    externalCountryReference: '7af46f36-6cc9-418b-bd0d-247f07b59541',
    dialCode: '+258'
  },
  {
    code: 'MM',
    name: 'Myanmar',
    externalCountryReference: 'c8f4a245-43d1-4dc7-8b77-69b5c171c15d',
    dialCode: '+95'
  },
  {
    code: 'NA',
    name: 'Namibia',
    externalCountryReference: 'e334e593-abe1-4803-9741-13253706ce76',
    dialCode: '+264'
  },
  {
    code: 'NR',
    name: 'Nauru',
    externalCountryReference: '45909647-d707-4c36-bb8d-0ece65ef7008',
    dialCode: '+674'
  },
  {
    code: 'NP',
    name: 'Nepal',
    externalCountryReference: '286643b4-effb-47fe-b441-9802a5bcd69d',
    dialCode: '+977'
  },
  {
    code: 'NL',
    name: 'Netherlands',
    externalCountryReference: 'afee9c65-f542-44ad-aebc-eb5e691d4053',
    localizationKey: 'Nederländerna',
    dialCode: '+31'
  },
  {
    code: 'NC',
    name: 'New Caledonia',
    externalCountryReference: 'b8d2d283-f0e8-450e-a0ac-ac814e8ccaae',
    dialCode: '+687'
  },
  {
    code: 'NZ',
    name: 'New Zealand',
    externalCountryReference: 'fb01bb9a-e3bd-4a68-b4b3-918e01bb6365',
    localizationKey: 'NyaZeeland',
    dialCode: '+64'
  },
  {
    code: 'NI',
    name: 'Nicaragua',
    externalCountryReference: '71fe19fd-ac14-4e8f-a438-d84850fee52e',
    localizationKey: 'Nicaragua',
    dialCode: '+505'
  },
  {
    code: 'NE',
    name: 'Niger',
    externalCountryReference: 'c67f5c8b-e5a6-4a1a-af35-1255779a001a',
    dialCode: '+227'
  },
  {
    code: 'NG',
    name: 'Nigeria',
    externalCountryReference: 'd04ba042-987a-4cc7-9a18-8f8c5fadd86e',
    dialCode: '+234'
  },
  {
    code: 'NU',
    name: 'Niue',
    externalCountryReference: '1e8a75ed-0d9e-46c4-aea7-234b88a7c771',
    dialCode: '+683'
  },
  {
    code: 'NF',
    name: 'Norfolk Island',
    externalCountryReference: 'c9581330-9ec0-427a-b157-dd33c2687566',
    dialCode: '+672'
  },
  {
    code: 'MP',
    name: 'Northern Mariana Islands',
    externalCountryReference: '420b41f1-b43e-452c-8bca-de4c6411a891',
    dialCode: '+1670'
  },
  {
    code: 'NO',
    name: 'Norway',
    externalCountryReference: '33dc6d6f-84c9-4c97-8dd4-5f6b091052d1',
    localizationKey: 'Norge',
    dialCode: '+47'
  },
  {
    code: 'OM',
    name: 'Oman',
    externalCountryReference: '0ae2575e-f18e-446e-b352-4c0bcb95cac5',
    localizationKey: 'Oman',
    dialCode: '+968'
  },
  {
    code: 'PK',
    name: 'Pakistan',
    externalCountryReference: '4ede0b8a-425c-4ea6-8481-0e224f359261',
    dialCode: '+92'
  },
  {
    code: 'PW',
    name: 'Palau',
    externalCountryReference: '64c2e351-57da-459c-9f3b-3bd08ba394c8',
    dialCode: '+680'
  },
  {
    code: 'PS',
    name: 'Palestinian Territory, Occupied',
    externalCountryReference: 'd454acfd-d121-49e4-94a7-542f5a620fa9',
    dialCode: '+970'
  },
  {
    code: 'PA',
    name: 'Panama',
    externalCountryReference: 'b2377d9f-24b3-49c6-98b4-dcd33e03f34b',
    dialCode: '+507'
  },
  {
    code: 'PG',
    name: 'Papua New Guinea',
    externalCountryReference: '31e16ba7-daad-455d-9662-53d668dbb09f',
    dialCode: '+675'
  },
  {
    code: 'PY',
    name: 'Paraguay',
    externalCountryReference: '5937bc0e-66b8-4dec-ba24-c2444b522c30',
    dialCode: '+595'
  },
  {
    code: 'PE',
    name: 'Peru',
    externalCountryReference: '0d33c15b-eb61-40a2-8ec9-a5026db64a9d',
    localizationKey: 'Peru',
    dialCode: '+51'
  },
  {
    code: 'PH',
    name: 'Philippines',
    externalCountryReference: 'b4a3e28c-8b92-49bb-a1b4-f5fc1f781fc8',
    dialCode: '+63'
  },
  {
    code: 'PN',
    name: 'Pitcairn',
    externalCountryReference: 'fd23ee10-3c12-4ac0-b470-13f4837e7a33',
    dialCode: '+64'
  },
  {
    code: 'PL',
    name: 'Poland',
    externalCountryReference: '587a75af-8957-4d4c-b0f9-1e1db5a428f1',
    localizationKey: 'Polen',
    dialCode: '+48'
  },
  {
    code: 'PT',
    name: 'Portugal',
    externalCountryReference: '35a89439-6b73-42ce-9d39-8930034fcc2b',
    localizationKey: 'Portugal',
    dialCode: '+351'
  },
  {
    code: 'PR',
    name: 'Puerto Rico',
    externalCountryReference: '82283eb2-0dea-4c1e-a159-ed486eb5d0b6',
    dialCode: '+1'
  },
  {
    code: 'QA',
    name: 'Qatar',
    externalCountryReference: '1dc3541e-179e-44a8-819c-12485af19234',
    localizationKey: 'Qatar',
    dialCode: '+974'
  },
  {
    code: 'RE',
    name: 'Réunion',
    externalCountryReference: '90210660-a807-41c4-8fff-98e21e06299d',
    localizationKey: 'Réunion',
    dialCode: '+262'
  },
  {
    code: 'RO',
    name: 'Romania',
    externalCountryReference: 'c5510892-45b5-4e34-aba8-04343424dc8f',
    localizationKey: 'Rumänien',
    dialCode: '+40'
  },
  {
    code: 'RU',
    name: 'Russia',
    externalCountryReference: '3627992a-408f-4417-bdf8-2ccae4c82bc4',
    localizationKey: 'Ryssland',
    dialCode: '+7'
  },
  {
    code: 'RW',
    name: 'Rwanda',
    externalCountryReference: '4a2034ce-30e3-4b16-b893-f7d2342d4482',
    dialCode: '+250'
  },
  {
    code: 'BL',
    name: 'Saint Barthélemy',
    externalCountryReference: '3030be44-9e7d-4816-bdea-a6a001c5db56',
    dialCode: '+590'
  },
  {
    code: 'SH',
    name: 'Saint Helena, Ascension and Tristan da Cunha',
    externalCountryReference: 'a186faf4-9c6c-4534-a9c2-2365f093d180',
    dialCode: '+290'
  },
  {
    code: 'KN',
    name: 'Saint Kitts and Nevis',
    externalCountryReference: '49ea4bb4-d924-4712-83e1-3855b86141a8',
    dialCode: '+1869'
  },
  {
    code: 'LC',
    name: 'Saint Lucia',
    externalCountryReference: 'eacf32d0-1220-4d0b-87fc-03b8d8d1c165',
    dialCode: '+1758'
  },
  {
    code: 'MF',
    name: 'Saint Martin (French part)',
    externalCountryReference: '74216520-b263-4fc3-8cf4-24036acc0b72',
    dialCode: '+590'
  },
  {
    code: 'PM',
    name: 'Saint Pierre and Miquelon',
    externalCountryReference: 'a7137313-2616-41bb-afa0-d650db455f12',
    dialCode: '+508'
  },
  {
    code: 'VC',
    name: 'Saint Vincent and the Grenadines',
    externalCountryReference: '2fca7766-8c88-4be4-873d-753d935df27e',
    dialCode: '+1784'
  },
  {
    code: 'WS',
    name: 'Samoa',
    externalCountryReference: '1cea0f5e-a5bd-49f4-982b-ddd7260f1be2',
    dialCode: '+685'
  },
  {
    code: 'SM',
    name: 'San Marino',
    externalCountryReference: '12493093-2bb5-43f4-8f06-c4ad45abbd7d',
    dialCode: '+378'
  },
  {
    code: 'ST',
    name: 'Sao Tome and Principe',
    externalCountryReference: 'bf32882a-0c97-4fd0-af5a-1456d8e5eb70',
    dialCode: '+239'
  },
  {
    code: 'SA',
    name: 'Saudi Arabia',
    externalCountryReference: 'fb2ba69b-3891-4a67-84dc-472e0fd1ebf3',
    localizationKey: 'Saudiarabien',
    dialCode: '+966'
  },
  {
    code: 'SN',
    name: 'Senegal',
    externalCountryReference: 'b55ddbfa-98b4-4514-859d-da7e10fe7385',
    dialCode: '+221'
  },
  {
    code: 'RS',
    name: 'Serbia',
    externalCountryReference: '501d7f15-8fa8-4113-85c5-03f42e399243',
    localizationKey: 'Serbien',
    dialCode: '+381'
  },
  {
    code: 'SC',
    name: 'Seychelles',
    externalCountryReference: '9086e365-74f6-4961-ba08-bc4dcdf9e7c8',
    dialCode: '+248'
  },
  {
    code: 'SL',
    name: 'Sierra Leone',
    externalCountryReference: '7dc6875d-6c3d-4508-92fa-cd2f25ff1be7',
    dialCode: '+232'
  },
  {
    code: 'SG',
    name: 'Singapore',
    externalCountryReference: '404011a5-5ad7-4c48-a295-aae6b5de1e7f',
    localizationKey: 'Singapore',
    dialCode: '+65'
  },
  {
    code: 'SX',
    name: 'Sint Maarten (Dutch part)',
    externalCountryReference: '47985206-6e3f-4bd2-a29d-e2f336a2ff41',
    dialCode: '+1721'
  },
  {
    code: 'SK',
    name: 'Slovakia',
    externalCountryReference: '66e089d5-deb9-44c6-b117-33c57aa1af53',
    localizationKey: 'Slovakien',
    dialCode: '+421'
  },
  {
    code: 'SI',
    name: 'Slovenia',
    externalCountryReference: 'b2ceb87f-0e94-46bd-9e6f-f748bdce41f7',
    localizationKey: 'Slovenien',
    dialCode: '+386'
  },
  {
    code: 'SB',
    name: 'Solomon Islands',
    externalCountryReference: '0e7cf7f9-f23c-48c7-87f6-55662e2899b8',
    dialCode: '+677'
  },
  {
    code: 'SO',
    name: 'Somalia',
    externalCountryReference: 'bb5b99d0-a0d2-444c-a6ab-3e733ba67ce6',
    dialCode: '+252'
  },
  {
    code: 'ZA',
    name: 'South Africa',
    externalCountryReference: 'a1b1dce6-e7a6-4080-9411-13579c4a2be3',
    localizationKey: 'Sydafrika',
    dialCode: '+27'
  },
  {
    code: 'GS',
    name: 'South Georgia and the South Sandwich Islands',
    externalCountryReference: 'a21ce752-5461-4de7-a171-cc42d02cc850',
    dialCode: '+500'
  },
  {
    code: 'SS',
    name: 'South Sudan',
    externalCountryReference: '3cb1f10c-3201-49a9-b7c1-70085b84f2d6',
    dialCode: '+211'
  },
  {
    code: 'ES',
    name: 'Spain',
    externalCountryReference: '7a5b6d16-3b7b-467f-8b74-bb545c96f923',
    localizationKey: 'Spanien',
    dialCode: '+34'
  },
  {
    code: 'LK',
    name: 'Sri Lanka',
    externalCountryReference: '92c96f76-7173-45ba-babf-46e4f0273734',
    dialCode: '+94'
  },
  {
    code: 'SD',
    name: 'Sudan',
    externalCountryReference: 'c40f941d-277d-437f-850c-9c814bead3d0',
    dialCode: '+249'
  },
  {
    code: 'SR',
    name: 'Suriname',
    externalCountryReference: '27607515-4a06-43b7-b4c4-e3ce6903cc16',
    dialCode: '+597'
  },
  {
    code: 'SJ',
    name: 'Svalbard and Jan Mayen',
    externalCountryReference: '78c437cb-3afa-489c-b5de-8f0478e6fd99',
    dialCode: '+47'
  },
  {
    code: 'SZ',
    name: 'Swaziland',
    externalCountryReference: '2e1d24f0-d9a0-4369-8f28-6dcbeba7cd6f',
    dialCode: '+268'
  },
  {
    code: 'SE',
    name: 'Sweden',
    externalCountryReference: '97557cc3-5c1e-407c-a819-1c35cdfa5052',
    localizationKey: 'Sverige',
    dialCode: '+46'
  },
  {
    code: 'CH',
    name: 'Switzerland',
    externalCountryReference: '46bfb811-0553-43a0-98f5-9a859b7d6064',
    localizationKey: 'Schweiz',
    dialCode: '+41'
  },
  {
    code: 'SY',
    name: 'Syria',
    externalCountryReference: 'fa4e615f-e2c1-4c68-a294-2b024a081f4c',
    localizationKey: 'Syrien',
    dialCode: '+963'
  },
  {
    code: 'TW',
    name: 'Taiwan',
    externalCountryReference: 'd5b87925-b890-4135-9557-41e085fb6a8a',
    localizationKey: 'Taiwan',
    dialCode: '+886'
  },
  {
    code: 'TJ',
    name: 'Tajikistan',
    externalCountryReference: '35a70e41-fbc7-428c-be4c-c046ea0ff6e2',
    dialCode: '+992'
  },
  {
    code: 'TZ',
    name: 'Tanzania',
    externalCountryReference: 'fdddeec6-56c7-4a9d-8c44-8985b8ee571e',
    dialCode: '+255'
  },
  {
    code: 'TH',
    name: 'Thailand',
    externalCountryReference: '0fa6defb-7094-477e-bf11-abcd9098ad61',
    localizationKey: 'Thailand',
    dialCode: '+66'
  },
  {
    code: 'TL',
    name: 'Timor-Leste',
    externalCountryReference: 'b82cc500-565a-4536-b68f-cf62b8f7a7da',
    dialCode: '+670'
  },
  {
    code: 'TG',
    name: 'Togo',
    externalCountryReference: '02d2d908-ada1-42d4-94d2-56417007dc7d',
    dialCode: '+228'
  },
  {
    code: 'TK',
    name: 'Tokelau',
    externalCountryReference: '69f3479a-b84a-4292-827c-a1eb5303838b',
    dialCode: '+690'
  },
  {
    code: 'TO',
    name: 'Tonga',
    externalCountryReference: '3d12c8d4-e926-427e-842f-c09d2ee53db6',
    dialCode: '+676'
  },
  {
    code: 'TT',
    name: 'Trinidad and Tobago',
    externalCountryReference: '4737345e-da54-4412-8e35-5c05139c65ac',
    dialCode: '+1868'
  },
  {
    code: 'TN',
    name: 'Tunisia',
    externalCountryReference: '5a053f23-17dc-4ee1-9c27-7bccfc005a3b',
    localizationKey: 'Tunisien',
    dialCode: '+216'
  },
  {
    code: 'TR',
    name: 'Turkey',
    externalCountryReference: '822e50fd-c076-4405-830b-3c115ce13d82',
    localizationKey: 'Turkiet',
    dialCode: '+90'
  },
  {
    code: 'TM',
    name: 'Turkmenistan',
    externalCountryReference: '6ec69cde-19fb-45b3-8200-0d1874a484a6',
    dialCode: '+993'
  },
  {
    code: 'TC',
    name: 'Turks and Caicos Islands',
    externalCountryReference: '9a0a4c6d-0ea3-4163-9d1d-b388b099890a',
    dialCode: '+1649'
  },
  {
    code: 'TV',
    name: 'Tuvalu',
    externalCountryReference: '59741d0f-3a59-4dcf-a545-3997dad2a2f1',
    dialCode: '+688'
  },
  {
    code: 'UG',
    name: 'Uganda',
    externalCountryReference: '47b9c5d5-2339-4a27-8e1f-64e487e9944d',
    dialCode: '+256'
  },
  {
    code: 'UA',
    name: 'Ukraine',
    externalCountryReference: '629b05b1-1841-4221-afbd-34e71db8fbd7',
    localizationKey: 'Ukraina',
    dialCode: '+380'
  },
  {
    code: 'AE',
    name: 'United Arab Emirates',
    externalCountryReference: '932146ca-538c-40f6-9d11-2cebc0975eb8',
    localizationKey: 'FörenadeArabemiraten',
    dialCode: '+971'
  },
  {
    code: 'GB',
    name: 'United Kingdom',
    externalCountryReference: '8012037b-64a0-468b-a7ba-292a997e73f2',
    localizationKey: 'Storbritannien',
    dialCode: '+44'
  },
  {
    code: 'US',
    name: 'United States',
    externalCountryReference: 'c839285d-8fa1-424a-91ef-24c0e39d0e87',
    dialCode: '+1'
  },
  {
    code: 'UM',
    name: 'United States Minor Outlying Islands',
    externalCountryReference: 'ac396191-5165-49e3-a38a-5607b197d9c0',
    localizationKey: 'FörentaStaternasMindreÖarIOceanienOchVästindien',
    dialCode: '+1'
  },
  {
    code: 'UY',
    name: 'Uruguay',
    externalCountryReference: 'ccbc6805-475c-4342-9420-b26b3e4c70c6',
    dialCode: '+598'
  },
  {
    code: 'UZ',
    name: 'Uzbekistan',
    externalCountryReference: '751c77cf-3ce3-475c-a917-548f89c29302',
    dialCode: '+998'
  },
  {
    code: 'VU',
    name: 'Vanuatu',
    externalCountryReference: '63a25733-dc7e-4397-9031-e4997ff10192',
    dialCode: '+678'
  },
  {
    code: 'VE',
    name: 'Venezuela',
    externalCountryReference: 'ee02fc25-0b4d-4623-beec-6608e6f599e6',
    localizationKey: 'Venezuela',
    dialCode: '+58'
  },
  {
    code: 'VN',
    name: 'Vietnam',
    externalCountryReference: '1380c104-808a-44d4-9207-66f29dd7272e',
    localizationKey: 'Vietnam',
    dialCode: '+84'
  },
  {
    code: 'VG',
    name: 'Virgin Islands, British',
    externalCountryReference: 'f5d9bbfa-926c-400c-961d-6d9cedc523e6',
    dialCode: '+1284'
  },
  {
    code: 'VI',
    name: 'Virgin Islands, U.S.',
    externalCountryReference: '8197d862-273c-45c8-bcff-e09d410d69cb',
    dialCode: '+1340'
  },
  {
    code: 'WF',
    name: 'Wallis and Futuna',
    externalCountryReference: 'fec80c97-2501-4ab9-84fc-88d5d210213e',
    dialCode: '+681'
  },
  {
    code: 'EH',
    name: 'Western Sahara',
    externalCountryReference: '9e5df681-87f2-4388-8e55-6be6b138835a',
    dialCode: '+212'
  },
  {
    code: 'YE',
    name: 'Yemen',
    externalCountryReference: 'c6ef7e16-c2e6-4ad5-8cc2-46cff033bc1e',
    dialCode: '+967'
  },
  {
    code: 'ZM',
    name: 'Zambia',
    externalCountryReference: 'ce38b883-30cf-4700-8df4-159e6bb519ba',
    dialCode: '+260'
  },
  {
    code: 'ZW',
    name: 'Zimbabwe',
    externalCountryReference: 'a4148b9d-a727-4d21-8500-65900e053ad3',
    dialCode: '+263'
  },
  {
    code: 'other',
    name: 'Other',
    externalCountryReference: 'other'
  }
]

export const getReferenceForCountry = (country: string = 'other') => {
  const result = countryMap.find(item => item.code === country)
  return result?.externalCountryReference || 'other'
}
